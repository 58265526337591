import { axiosInstanceCreator } from "./storeUtils";
import { AccountAgent, IAccountAgent } from "area/account/AccountAgent";
import { IListingAgent, ListingAgent } from "area/manage/listing/ListingAgent";
import { IPropertyAgent, PropertyAgent } from "area/manage/property/PropertyAgent";
import { ISearchAgent, SearchAgent } from "area/search/SearchAgent";
import { IUserAgent, UserAgent } from "area/user/UserAgent";
import AccountStore from "area/account/AccountStore";
import ListingStore from "area/manage/listing/ListingStore";
import PropertyStore from "area/manage/property/PropertyStore";
import SearchStore from "area/search/SearchStore";
import UserStore from "area/user/UserStore";
import RouterStore from "./RouterStore";
import { SearchFiltersHelper } from "area/search/models/SearchFiltersHelper";
import { IRootStore } from "stores";
import { MockSearchAgent } from "area/search/__mocks__/MockSearchAgent";
import { MockAccountAgent } from "area/account/__mocks__/MockAccountAgent";
import { History } from 'history';
import { createBrowserHistory } from "history";
import PaymentStore from "area/manage/payment/PaymentStore";
import { IPaymentAgent, PaymentAgent } from "area/manage/payment/PaymentAgent";

const base = process.env.REACT_APP_API_URL + "/";

const isMockedEnvironment = !!process.env.STORYBOOK || process.env.NODE_ENV === 'test' || process.env.REACT_APP_MOCK_MODE === 'true';

const createAxios = (baseURL: string) => axiosInstanceCreator({ baseURL });

let searchAgent:ISearchAgent;
let accountAgent:IAccountAgent;
let userAgent:IUserAgent;
let propertyAgent:IPropertyAgent;
let listingAgent:IListingAgent;
let paymentAgent: IPaymentAgent;

if (isMockedEnvironment) {
    console.log('Mock Environment is in use')
    searchAgent = new MockSearchAgent(createAxios(base));
    accountAgent = new MockAccountAgent(createAxios(base));
    userAgent = new UserAgent(createAxios(base));
    propertyAgent = new PropertyAgent(createAxios(base));
    listingAgent = new ListingAgent(createAxios(base));
    paymentAgent = new PaymentAgent(createAxios(base))
} else {
    searchAgent = new SearchAgent(createAxios(base));
    accountAgent = new AccountAgent(createAxios(base));
    userAgent = new UserAgent(createAxios(base));
    propertyAgent = new PropertyAgent(createAxios(base));
    listingAgent = new ListingAgent(createAxios(base));
    paymentAgent = new PaymentAgent(createAxios(base))
} 

export class RootStore implements IRootStore {

    history: History<unknown>;
    searchStore: SearchStore;
    searchHelper: SearchFiltersHelper;
    accountStore: AccountStore;
    userStore: UserStore;
    propertyStore: PropertyStore;
    listingStore: ListingStore;
    routerStore: RouterStore;
    paymentStore: PaymentStore;

    constructor() {
        this.history = createBrowserHistory();;
        this.searchStore = new SearchStore(searchAgent, this);
        this.searchHelper = new SearchFiltersHelper(this)
        this.accountStore = new AccountStore(accountAgent, this);
        this.userStore = new UserStore(userAgent, this);
        this.propertyStore = new PropertyStore(propertyAgent, this);
        this.listingStore = new ListingStore(listingAgent, this);
        this.routerStore = new RouterStore(this);
        this.paymentStore = new PaymentStore(paymentAgent, this)
    }
}



