import { AxiosInstance } from "axios";
import { requests } from "stores/storeUtils";
import { PaymentIntentDto } from "./dtos";

export class PaymentAgent implements IPaymentAgent {
    constructor(private api: AxiosInstance) {}

    requests = requests(this.api);

    getPaymentIntent(listingId: string): Promise<PaymentIntentDto> {
        return this.requests.post<PaymentIntentDto>('manage/payments/getPaymentIntent', {id: listingId})
    }
    setupIntent(): Promise<void> {
        return this.requests.post("stripe/setupIntent", {})
    }
    addPaymentMethod(paymentMethodId: string): Promise<void> {
        return this.requests.post<void>("stripe/addPaymentMethod", {id: paymentMethodId})
    }
}

export interface IPaymentAgent {
    getPaymentIntent(listingId: string): Promise<PaymentIntentDto>;
    setupIntent(): Promise<void>;
    addPaymentMethod(paymentMethodId: string): Promise<void>;
}
