import { FieldValues } from "react-hook-form";
import { SelectRef } from "components/models";

export interface AddressMatch {
    id: string;
    description: string;
}

export type PaymentIntent = {
    paymentIntentId: string;
    clientSecret: string;
}

export interface LocationDto {
    id: string;
    streetNumber: string;
    streetName: string;
    postalCode: string;
    suburbOrCity: string;
    council?: any;
    regionOrState: string;
    country: string;
    fullAddress: string;
}

export type PropertyCreateDto = {
    propertyTypeId: number;
    description: string;
    bedrooms: number;
    bathrooms: number;
    parkingSpaces: number;
    landSize: number;
    addressId: string;
    addressDescription: string;
    file: File
}

export type PropertyUpdateDto = {
    id: string;
    propertyTypeId: number;
    description: string;
    bedrooms: number;
    bathrooms: number;
    parkingSpaces: number;
    landSize: number;
    addressId: string;
    addressDescription: string;
}

// export interface PropertyBase {
//     listingType?: string;
//     propertyTypeId: number;
//     description: string;
//     bedrooms: number;
//     bathrooms: number;
//     parkingSpaces: number;
//     landSize: number;
//     address: AddressMatch;
// }

export interface PropertyDto {
    id: string;
    createdById: string;
    created: Date;
    name: string;
    mainImage: string;
    listingType?: string;
    slug: string;
    suburbSlug: string;
    propertyTypeId: number;
    description: string;
    bedrooms: number;
    bathrooms: number;
    parkingSpaces: number;
    landSize: number;
    address: AddressMatch;
    location?: LocationDto;
}

export interface PropertyType {
    id: number;
    type: string;
}

export interface Feature {
    id: number;
    name: string;
    outdoor: boolean;
}

export interface Lookups {
    propertyTypes: PropertyType[];
    features: Feature[];
}

export function ListingCreateMapper(data: FieldValues): ListingCreateDto {
    const mappedData = {
        ...data,
        listingType: parseInt(data.listingType)
    } as ListingCreateDto;
    return mappedData;
}

export function ListingUpdateMapper(data: FieldValues): ListingDto {
    const mappedData = {
        ...data,
        listingType: parseInt(data.listingType)
    } as ListingDto;
    return mappedData;
}



// export class ListingCreate implements ListingCreateDto {

//     readonly title: string = '';
//     readonly listingType: number = 0;

//     constructor(data: FieldValues) {

//         // Object.assign(this, data);

//         for (let key in data) {
//             this[key] = data[key];
//         }

//         /* or loop for keys 
        
//         for (let key in data) {
//         this[key] = data[key];
//         }

//         */
//     };
// }

// export class ListingUpdate {

//     readonly title: string = '';
//     readonly listingType: number = 0;

//     constructor(data: FieldValues) {

//         for (let key in data) {
//             this[key] = data[key];
//         }
//     };
// }



export type ListingCreateDto = Omit<ListingDto, 
"id" | 
"userAction" |
"property" |
"listingRef"
>;

export interface ListingDto {
    id?: string;
    title: string;
    listingDescription: string;
    listingRef: string;
    
    propertyId: string;
    property?: PropertyDto;
    // dateCreated: Date | string | null;
    // createdById: string;
    
    // desiredDateListingStart: number;
    dateListingStarts: string;
    dateListingExpires: string;

    // dateListingExpires: Date | string | null; // date the listing will expire
    contactEmail: string; // used to send emails to lister
    contactNumber: string; // can be landline or mobile
    showNumber: boolean;
    price: number;
    listingType: number; // rent, buy, auction etc
    
    // // Image
    // mainImage: string;
    // // Agency
    // agencyId: string | null; 
    
    // agency: AgencyDto;
    
    // agentId: string | null;
    
    // agent: AgentDto;
    
    // checker: CompletenessCheckerModelDto;
    
    // // Tracking fields for Ads
    userAction: UserActions;
    paymentIntentId?: string;
    clientSecret?: string;
    
    // processorAction: ProcessorActions; // tells the Processor what to do
    
    // isReady: boolean;
    
    // isConfirmed: boolean;
    // isLive: boolean;
    // isCancelled: boolean;
    // listingStatus: string;
    // isPublishedLive: boolean;
    
    // isPremium: boolean;  // default is standard
    
    // userName: string;
    
    // userId: string;
    
    // isListedByAgent: boolean;
    
    // isPrivateSeller: boolean;  // default is not private seller
}


export interface AgencyDto {
    id: string;

    agencyCompanyName: string; // Ray White for example

    agencyOfficeName: string; // Ray White Runcorn for example

    logoImageUrl: string;

    brandColor: string;
}

export interface AgentDto {
    id: string;

    firstName: string;

    lastName: string;

    mobileNumber: string;

    emailAddress: string;

    fullName: string;
}

export interface CompletenessCheckerModelDto {
    errorCount: number;

    errorMessages: ErrorDto[];

    complete: boolean;
}

export interface ErrorDto {
    code: number;

    item: string;

    error: string;
}

export enum UserActions {
    None = 0,
    Cancel = 1,
    ReList = 2,
    Confirm = 3
}

export enum ProcessorActions {
    Upsert,
    Cancel,
    ReList,
    Expire
}

export enum ListingTypes {
    Sale = 0,
    Rent = 1
}

export enum ListingTypesLabels {
    "Sale" = 0,
    "Rent" = 1
}


export const listingTypes: SelectRef[] = [
    {
        id: 0,
        value: 'Sale'
    },
    {
        id: 1,
        value: 'Rent'
    },
]

export const userActions: SelectRef[] = [
    
    {
        id: UserActions.None,
        value: "None",
    },
    {
        id: UserActions.Cancel,
        value: "Cancel",
    },
    {
        id: UserActions.ReList,
        value: "ReList",
    },
    {
        id: UserActions.Confirm,
        value: "Confirm",
    }
]

export const DEFAULT_LISTING_TYPE = 'Sale';
