import { PhotoIcon } from '@heroicons/react/20/solid'
import React from 'react'

const ImageButtons = () => {

    const imageButton = (icon: any, label: string) => {

        return (
            <div className="bg-white p-3 border-2 border-dom-grey-light text-dom-grey-hover flex flex-row">
                {icon}
                {label}
            </div>
        )
    }

    return (
        <div id="main-image-buttons" className="flex flex-row mt-[-30px] z-10">
            {imageButton(<PhotoIcon className="h-6 w-6" />, "Photos")}
            {imageButton(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true"><path d="M22 2V22H2V11H4V20H9V14H11V20H20V11H17V9H20V4H11V9H14V11H9V4H4V8H2V2H22Z" fill="currentColor"></path></svg>, "Floorplan")}
            {imageButton(<PhotoIcon className="h-6 w-6" />, "Video")}
            {imageButton(<PhotoIcon className="h-6 w-6" />, "virtual tour")}
        </div>
    )
}

export default ImageButtons