import { makeAutoObservable, toJS, } from "mobx";
import { CheckBoxModel, FeatureType, FilterValue, FilterValues, HiLowValue, ModeEnums, SearchValueEnums, PriceModel, SpaceExactModel, SpaceModel } from ".";
import { emptyHiLowValue } from "./defaultValues";
import { IRootStore } from "stores";


export class SearchFiltersHelper {

    constructor(rootStore: IRootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    rootStore: IRootStore;

    filterValues: FilterValue = {
        mode: ModeEnums.buy,
        price: null,
        bedRooms: [],
        bedRoomsExact: null,
        bathRooms: [],
        bathRoomsExact: null,
        parking: [],
        parkingExact: null,
        pTypes: []
    }

    priceBuyValue: PriceModel = null;
    priceRentValue: PriceModel = null;

    isReset: boolean = false;
    filtersLoaded: boolean = false;


    public loadFilterValues = async () => {
        await this.loadModeValues();
        await this.loadPricingTypes();
        await this.loadPropertyTypes();
        await this.loadBedroomValues();
        await this.loadBathroomValues();
        await this.loadParkingValues();

        this.filtersLoaded = true;
        this.isReset = false;

    }

    public resetFilters = async () => {
        this.isReset = true;
        await this.loadFilterValues();
    }

    private processFitlerValues = () => {

        let pTypes: FeatureType[] = []
        this.filterValues.pTypes.filter(x => x.checked == true).map(item => {
            pTypes.push({
                id: item.id,
                label: item.label,
                parent: item.parent,
                value: item.value,
                checked: item.checked
            } as FeatureType)
        })

        let pricevalues: HiLowValue = emptyHiLowValue;

        if (this.filterValues.mode === ModeEnums.buy) {
            pricevalues.lowValue = this.priceBuyValue.lowMark.value;
            pricevalues.highValue = this.priceBuyValue.highMark.value;
        }

        if (this.filterValues.mode === ModeEnums.rent) {
            pricevalues.lowValue = this.priceRentValue.lowMark.value;
            pricevalues.highValue = this.priceRentValue.highMark.value;
        }

        const values: FilterValues = {
            mode: this.filterValues.mode,
            bedRooms: this.filterValues.bedRooms.find(x => x.selected == true).value,
            bedRoomsExact: this.filterValues.bedRoomsExact.value,
            pTypes: pTypes,
            bathRooms: this.filterValues.bathRooms.find(x => x.selected == true).value,
            bathRoomsExact: this.filterValues.bathRoomsExact.value,
            parking: this.filterValues.parking.find(x => x.selected == true).value,
            parkingExact: this.filterValues.parkingExact.value,
            price: pricevalues
        }

        return values;
    }

    public applyFilterToStore = () => {
        this.rootStore.searchStore.applyFilters(this.processFitlerValues());
    }


    // Mode -------------------------------------------------------------------------------------------------------- //

    public setMode = (mode: string) => {
        if (mode !== this.filterValues.mode) {
            this.filterValues.mode = mode;
        }
    }

    // Load Types ----------------------------------------------------------------------------------------------- //

    private loadModeValues = (): Promise<void> => {
        const parentMode = this.rootStore.searchStore.searchValues.mode;
        this.filterValues.mode = toJS(parentMode);

        if (this.isReset) {
            this.filterValues.mode = ModeEnums.buy;
        }

        return Promise.resolve();
    }

    private loadPricingTypes = (): Promise<void> => {

        const parentValue = this.rootStore.searchStore.searchValues.price;

        const priceBuyMarks = this.rootStore.searchStore.priceBuyMarks;
        const priceBuyDefault = this.rootStore.searchStore.priceBuyDefault;

        const priceRentMarks = this.rootStore.searchStore.priceRentMarks;
        const priceRentDefault = this.rootStore.searchStore.priceRentDefault;

        if (!this.isReset) {
            if (this.filterValues.mode == ModeEnums.buy && parentValue.highValue !== 0) {
                const lowMark = priceBuyMarks.find(x => x.value === parentValue.lowValue);
                const highMark = priceBuyMarks.find(x => x.value === parentValue.highValue);
                this.priceBuyValue = new PriceModel(lowMark, highMark);
            } else {
                this.priceBuyValue = new PriceModel(priceBuyDefault.lowMark, priceBuyDefault.highMark);
            }
        }

        if (this.isReset) {
            this.priceBuyValue = new PriceModel(priceBuyDefault.lowMark, priceBuyDefault.highMark);
        }

        if (!this.isReset) {

            if (this.filterValues.mode == ModeEnums.rent && parentValue.highValue !== 0) {
                const lowMark = priceRentMarks.find(x => x.value === parentValue.lowValue);
                const highMark = priceRentMarks.find(x => x.value === parentValue.highValue);
                this.priceRentValue = new PriceModel(lowMark, highMark);
            } else {
                this.priceRentValue = new PriceModel(priceRentDefault.lowMark, priceRentDefault.highMark);
            }

        }
        if (this.isReset) {
            this.priceRentValue = new PriceModel(priceRentDefault.lowMark, priceRentDefault.highMark);
        }

        return Promise.resolve();
    }

    private loadPropertyTypes = (): Promise<void> => {

        if (this.isReset) {
            this.filterValues.pTypes.forEach(x => {
                x.checked = false;
            })
        }

        if (this.filterValues.pTypes.length == 0) {
            const defaultMarks = this.rootStore.searchStore.propertyTypeMarks;

            defaultMarks.forEach(dto => {
                const item = new CheckBoxModel(dto);
                const found = this.rootStore.searchStore.searchValues.pTypes.find(y => y.id == dto.id);
                if (found) {
                    item.checked = true;
                }

                this.filterValues.pTypes.push(item);
            })
        }

        return Promise.resolve();
    }

    private loadBedroomValues = (): Promise<void> => {

        if (this.isReset) {
            this.filterValues.bedRooms[0].selected = true;
            this.filterValues.bedRoomsExact = new SpaceExactModel(false);
        }

        if (this.filterValues.bedRooms.length == 0) {
            const parentValue = this.rootStore.searchStore.searchValues.bedRooms;
            const spaceMarks = this.rootStore.searchStore.bedRoomMarks;

            spaceMarks.forEach((dto, index) => {
                if (index === 0) dto.optionalLabel = 'Studio'
                const item = new SpaceModel(dto, this, SearchValueEnums.bedRooms);

                if (parentValue == item.value) {
                    item.selected = true;
                }

                this.filterValues.bedRooms.push(item);
            })
        }

        if (this.filterValues.bedRoomsExact == null) {
            let exactVal = false;
            if (!this.isReset) {
                exactVal = this.rootStore.searchStore.searchValues.bedRoomsExact;
            }
            this.filterValues.bedRoomsExact = new SpaceExactModel(exactVal);
        }
        return Promise.resolve();
    }

    private loadBathroomValues = (): Promise<void> => {
        if (this.isReset) {
            this.filterValues.bathRooms[0].selected = true;
            this.filterValues.bathRoomsExact = new SpaceExactModel(false);
        }

        if (this.filterValues.bathRooms.length == 0) {
            const parentValue = this.rootStore.searchStore.searchValues.bathRooms;
            const spaceMarks = this.rootStore.searchStore.bathRoomMarks;

            spaceMarks.forEach((dto, index) => {
                const item = new SpaceModel(dto, this, SearchValueEnums.bathRooms);

                if (parentValue == item.value) {
                    item.selected = true;
                }

                this.filterValues.bathRooms.push(item);
            })
        }

        if (this.filterValues.bathRoomsExact == null) {
            let exactVal = false;
            if (!this.isReset) {
                exactVal = this.rootStore.searchStore.searchValues.bathRoomsExact;
            }
            this.filterValues.bathRoomsExact = new SpaceExactModel(exactVal);
        }
        return Promise.resolve();
    }

    private loadParkingValues = (): Promise<void> => {
        if (this.isReset) {
            this.filterValues.parking[0].selected = true;
            this.filterValues.parkingExact = new SpaceExactModel(false);
        }

        if (this.filterValues.parking.length == 0) {
            const parentValue = this.rootStore.searchStore.searchValues.parking;
            const spaceMarks = this.rootStore.searchStore.parkingMarks;

            spaceMarks.forEach((dto, index) => {
                const item = new SpaceModel(dto, this, SearchValueEnums.parking);

                if (parentValue == item.value) {
                    item.selected = true;
                }

                this.filterValues.parking.push(item);
            })
        }

        if (this.filterValues.parkingExact == null) {
            let exactVal = false;
            if (!this.isReset) {
                exactVal = this.rootStore.searchStore.searchValues.parkingExact;
            }
            this.filterValues.parkingExact = new SpaceExactModel(exactVal);
        }
        return Promise.resolve();
    }


    get propertyTypes() {
        return this.filterValues.pTypes
    }

    get propertyTypesChecked() {
        return this.filterValues.pTypes.filter(x => x.checked == true).length > 0;
    }

    clearPropertyTypes = () => {
        this.filterValues.pTypes.forEach(item => {
            item.checked = false;
        })
    }


}