import { createContext, useContext } from "react";
import { RootStore } from "./RootStore";

export const rootStore = new RootStore();

export const StoreContext = createContext(rootStore);

export function useStore() {
    return useContext(StoreContext);
}
