import { observer } from 'mobx-react-lite';
import { useState } from 'react'
import { SimpleRangeSlider as Slider } from 'components'
import ClearButton from './ClearButton';
import { Mark, PriceModel } from 'area/search/models';


type SlideControlProps = {
  header: string;
  target: string;
  showClearButton?: boolean;
  value: PriceModel;
  options: Mark[];
  defaultValue: PriceModel;
}

const DEFAULT_LABEL: string = 'Any'

const PriceControl = ({ header, target, showClearButton, value, options, defaultValue }: SlideControlProps) => {

  const [label, setLabel] = useState<string>(DEFAULT_LABEL);

  const handleChange = (values: number[]) => {
    const lowMark = options.find(x => x.index == values[0])
    const highMark = options.find(x => x.index == values[1])
    value.setLowValues(lowMark);
    value.setHighValues(highMark);

    processLabel();
  }

  const processLabel = () => {
    let returnLabel: string = DEFAULT_LABEL;

    if (value.lowMark.value !== defaultValue.lowMark.value && value.highMark.value === defaultValue.highMark.value) {
      returnLabel = `Over ${value.lowMark.label}`
    }

    if (value.lowMark.value === defaultValue.lowMark.value && value.highMark.value !== defaultValue.highMark.value) {
      returnLabel = `Under ${value.highMark.label}`
    }

    if (value.lowMark.value !== defaultValue.lowMark.value && value.highMark.value !== defaultValue.highMark.value) {
      returnLabel = `${value.lowMark.label} - ${value.highMark.label}`
    }

    setLabel(returnLabel);
  }

  function handleClear() {
  }

  return (
    <div className='flex flex-col gap-2 p-3'>
      <div className='flex justify-between'>
        <h2 className='title-16-grey'>{header}</h2>
        {/* {isDefault && showClearButton && <ClearButton handleClear={handleClear} />} */}
      </div>
      <div className='title-16-grey-normal'>
        {label}
      </div>
      <div>
        <Slider handleChange={handleChange} defaults={[value.lowMark.index, value.highMark.index]} marks={options} />
      </div>
      <div>
        
      </div>
    </div>
  )
}

export default observer(PriceControl);