
import lodash from 'lodash'
import { FeatureType, HiLowValue, Mark } from '.'

export const rentMarks: Mark[] = [
    { label: 'Any', value: 0 },
    { label: '$50', value: 50 },
    { label: '$100', value: 100 },
    { label: '$150', value: 150 },
    { label: '$200', value: 200 },
    { label: '$250', value: 250 },
    { label: '$300', value: 300 },
    { label: '$350', value: 350 },
    { label: '$400', value: 400 },
    { label: '$450', value: 450 },
    { label: '$500', value: 500 },
    { label: '$550', value: 550 },
    { label: '$600', value: 600 },
    { label: '$650', value: 650 },
    { label: '$700', value: 700 },
    { label: '$750', value: 750 },
    { label: '$800', value: 800 },
    { label: '$850', value: 850 },
    { label: '$900', value: 900 },
    { label: '$950', value: 950 },
    { label: '$1000', value: 1000 },
    { label: '$1100', value: 1100 },
    { label: '$1200', value: 1200 },
    { label: '$1300', value: 1300 },
    { label: '$1400', value: 1400 },
    { label: '$1500', value: 1500 },
    { label: '$1600', value: 1600 },
    { label: '$1700', value: 1700 },
    { label: '$1800', value: 1800 },
    { label: '$1900', value: 1900 },
    { label: '$2000', value: 2000 },
    { label: '$2500', value: 2500 },
    { label: '$3000', value: 3000 },
    { label: '$3500', value: 3500 },
    { label: '$4000', value: 4000 },
    { label: '$4500', value: 4500 },
    { label: '$5000', value: 5000 },
    { label: '$5500', value: 5500 },
    { label: '$10000', value: 10000 },
]

export const buyMarks: Mark[] = [
    { label: 'Any', value: 0 },
    { label: '50k', value: 50000 },
    { label: '100k', value: 100000 },
    { label: '150k', value: 150000 },
    { label: '200k', value: 200000 },
    { label: '250k', value: 250000 },
    { label: '300k', value: 300000 },
    { label: '350k', value: 350000 },
    { label: '400k', value: 400000 },
    { label: '450k', value: 450000 },
    { label: '500k', value: 500000 },
    { label: '550k', value: 550000 },
    { label: '600k', value: 600000 },
    { label: '650k', value: 650000 },
    { label: '700k', value: 700000 },
    { label: '750k', value: 750000 },
    { label: '800k', value: 800000 },
    { label: '850k', value: 850000 },
    { label: '900k', value: 900000 },
    { label: '1m', value: 1000000 },
    { label: '1.1m', value: 1100000 },
    { label: '1.2m', value: 1200000 },
    { label: '1.3m', value: 1300000 },
    { label: '1.4m', value: 1400000 },
    { label: '1.5m', value: 1500000 },
    { label: '1.6m', value: 1600000 },
    { label: '1.7m', value: 1700000 },
    { label: '1.8m', value: 1800000 },
    { label: '1.9m', value: 1200000 },
    { label: '2m', value: 2000000 },
    { label: '2.25m', value: 2250000 },
    { label: '2.5m', value: 2500000 },
    { label: '2.75m', value: 2750000 },
    { label: '3m', value: 3000000 },
    { label: '3.5m', value: 3500000 },
    { label: '4m', value: 4000000 },
    { label: '5m', value: 5000000 },
    { label: '6m', value: 6000000 },
    { label: '7m', value: 7000000 },
    { label: '8m', value: 8000000 },
    { label: '9m', value: 9000000 },
    { label: '10m', value: 10000000 },
    { label: '11m', value: 11000000 },
    { label: '12m', value: 12000000 }
];

export const spaceMarks: Mark[] = [
    { label: 'Any', optionalLabel: '0', value: 0 },
    { label: '1+', optionalLabel: '1', value: 1 },
    { label: '2+', optionalLabel: '2', value: 2 },
    { label: '3+', optionalLabel: '3', value: 3 },
    { label: '4+', optionalLabel: '4', value: 4 },
    { label: '5+', optionalLabel: '5', value: 5 },
]


const propertySizeMeterSizes: number[] = [
    0, 50, 75, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1250, 1500, 1750, 2000, 3000, 4000, 5000, 10000, 100000
]

const propertySizeAcreSizes: number[] = [
    0, 1, 2, 3, 4, 5, 10, 25, 50, 75, 100, 250, 500, 1000, 100000
]

const propertySizeHectaresSizes: number[] = [
    0, 1, 2, 3, 4, 5, 10, 25, 50, 75, 100, 250, 500, 1000, 100000
]

export const roomsSizes: number[] = [
    0, 1, 2, 3, 4, 5, 100
]

export const features: string[] = [
    'apartment-unit-flat', 'block-of-units', 'duplex'
]

export const propertyTypes: FeatureType[] = [
    {
        label: "House",
        value: "house",
        parent: undefined,
        id: 1
    },
    {
        label: "Duplex",
        value: "duplex",
        parent: 1,
        id: 2
    },
    {
        label: "Free standing",
        value: "free-standing",
        parent: 1,
        id: 3
    },
    {
        label: "Semi detached",
        value: "semi-detached",
        parent: 1,
        id: 4
    },
    {
        label: "Terrace",
        value: "terrace",
        parent: 1,
        id: 5
    },
    {
        label: "Villa",
        value: "villa",
        parent: 1,
        id: 6
    },
    {
        label: "House and land package",
        value: "new-house-land",
        parent: 1,
        id: 7
    },
    {
        label: "Home designs",
        value: "new-home-designs",
        parent: 1,
        id: 8
    },
    {
        label: "Apartment",
        value: "apartment",
        parent: undefined,
        id: 9
    },
    {
        label: "Block of units",
        value: "block-of-units",
        parent: 9,
        id: 10
    },
    {
        label: "Penthouse",
        value: "penthouse",
        parent: 9,
        id: 11
    },
    {
        label: "Studio",
        value: "studio",
        parent: 9,
        id: 12
    },
    {
        label: "Appartment / unit / flat",
        value: "apartment-unit-flat",
        parent: 9,
        id: 13
    },
    {
        label: "Townhouse",
        value: "townhouse",
        parent: undefined,
        id: 14
    },
    {
        label: "Land",
        value: "land",
        parent: undefined,
        id: 15
    },
    {
        label: "Development site",
        value: "development-site",
        parent: 15,
        id: 16
    },
    {
        label: "Vacant land",
        value: "development-site",
        parent: 15,
        id: 17
    },
    {
        label: "Retirement",
        value: "retirement",
        parent: undefined,
        id: 18
    },
]

export const rentDefaults: HiLowValue = {
    lowValue: lodash.first(rentMarks)?.value!,
    highValue: lodash.last(rentMarks)?.value!
}

export const buyDefaults: HiLowValue = {
    lowValue: lodash.first(buyMarks)?.value!,
    highValue: lodash.last(buyMarks)?.value!
}

export const propertySizeDefaults: HiLowValue = {
    lowValue: lodash.first(propertySizeMeterSizes)!,
    highValue: lodash.last(propertySizeMeterSizes)!
}

export const spaceDefaults: number = lodash.first(spaceMarks)!.value

export const featureDefaults = features;

export const emptyHiLowValue: HiLowValue = {
    lowValue: 0,
    highValue: 0
}
