import { PaginatedResults } from "models/pagination"

import { AxiosInstance, AxiosResponse } from "axios";
import { requests } from "stores/storeUtils";
import { SearchListing, SearchLookups, Postcode } from "../models";

import {searchListings, paginatedResults} from "stubs/search-listings"
import { ISearchAgent } from "../SearchAgent";

const responseBody = <T> (response: AxiosResponse<T>) => response.data;

export class MockSearchAgent implements ISearchAgent {
    constructor(private api: AxiosInstance) {
    }
    

   requests = requests(this.api);

   find(params: URLSearchParams): Promise<PaginatedResults<SearchListing[]>> {
       return Promise.resolve(paginatedResults)
   }

   details(id: string): Promise<SearchListing> {
       return Promise.resolve(searchListings[1])
   }

   extraImages(id: string): Promise<string[]> {
    return Promise.resolve(searchListings[1].images)
    }

   filters(): Promise<SearchLookups> {
       return this.requests.get<SearchLookups>('search/lookups')
   }

   searchPostcode(request: string): Promise<Postcode[]> {
       return this.requests.get<Postcode[]>(`search/postcodes?request=${request}`)
   }

   findPostCodes(request: string[]): Promise<Postcode[]> {
    return this.requests.post<Postcode[]>(`search/postcodes`, request)
}

}

