import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { PaginatedResults } from "models/pagination";
import { toast } from "react-toastify";
import { rootStore } from "stores";

const responseBody = <T> (response: AxiosResponse<T>) => response.data;
const sleep = () => new Promise(resolve => setTimeout(resolve, 2000));

export const axiosInstanceCreator = (config: AxiosRequestConfig = {}) => {
    const api = axios.create(
      Object.assign(
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          responseType: "json"
        },
        config
      )
    );
  
    return api;
  };
  
export const requests = (axios: AxiosInstance) => {

  axios.interceptors.request.use((config) => {
    const token = rootStore.accountStore.token;
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  axios.interceptors.response.use(
    async (response) => {
      // un-comment sleep() to simulate slower api loading
      // await sleep();
      const pagination = response.headers['pagination'];
      if (pagination) {
        response.data = new PaginatedResults(response.data.items, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResults<any>>
      }
      return response;
    },
    (error: AxiosError) => {
  
      const { data, status } = error.response!;
      
      switch (status) {
        case 400:
          if (data.errors) {
            const modelStateErrors: string[] = [];
            for (const key in data.errors) {
              if (data.errors[key]) {
                modelStateErrors.push(data.errors[key]);
              }
            }
            throw modelStateErrors.flat();
          }
          toast.error(data.title);
          break;
        case 401:
          toast.error(data.title || data);
          break;
        case 500:
          // history.push({
          //   pathname: 'server-error',
          //   state: { error: data },
          // });
          break;
  
        default:
          break;
      }
      return Promise.reject(error.response);
    }
  );


    return {
        get: <T> (url: string) => axios.get<T>(url).then(responseBody),
        post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
        put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
        delete: <T> (url: string) => axios.delete<T>(url).then(responseBody),
        postForm: <T> (url: string, data: FormData) => axios.post<T>(url, data, {
          headers: {'Content-type': 'multipart/form-data'}
        }).then(responseBody),
        putForm: <T> (url: string, data: FormData) => axios.put<T>(url, data, {
          headers: {'Content-type': 'multipart/form-data'}
        }).then(responseBody)
    }
}

export function createFormData(item: any) {
  let formData = new FormData();
  for (const key in item) {
      formData.append(key, item[key])
  }
  return formData;
}
