import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import agent from "api/agent";
import NotFound from "common/errors/NotFound";
import LoadingComponent from "layout/LoadingComponent";
import { SearchListing } from "../models";
import { MainNavBar, OtherLinksBar } from "layout";
import ImageButtons from "./ImageButtons";
import AgentDetails from "./AgentDetails";
import { theme } from "area/manage";
import { useStore } from "stores";

export default function DetailsPage() {

    const { id } = useParams<{ id: string }>();
    const [listing, setListing] = useState<SearchListing | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const {
        searchStore: {
            getListingDetails
        },
      } = useStore();

    useEffect(() => {
        getListingDetails(id)
            .then(res => {
                setListing(res)
            })
            .catch(error => {})
            .finally(() => setLoading(false));
    }, [id, getListingDetails])

    const mdScreen = useMediaQuery(theme.breakpoints.up("md"));

    if (loading) return <LoadingComponent message='Loading result...' />;
    if (!listing) return <NotFound />

    return (
        <div className='flex flex-col'>

            {mdScreen && <OtherLinksBar />}
            <MainNavBar lightMode={false} />

            <div className='border-dom-grey-light border-t border-b'>
                <div className='max-w-[1280px] flex flex-col lg:flex-row lg:items-center mx-auto justify-start p-4'>
                    breadcrumbs bar
                </div>
            </div>

            <div id="main-image" className='max-w-[1680px] flex flex-row mx-auto'>
                <img className="object-cover object-center sm:w-[1680px] lg:h-[716px] md:h-[300px]" src={listing.mainImage} alt={listing.slug} />
            </div>

            <div>
                <div id="main-content" className="max-w-[1020px] flex flex-col mx-auto p-3">
                    <div className="flex justify-between">
                        <div className="grow pr-8">

                            <ImageButtons />

                            <div className="flex flex-col mt-10 gap-9">

                                <div id="property-info" className="flex flex-col gap-3">
                                    <h1>{listing.title}</h1>
                                    <p>{listing.address}</p>
                                    <p>{listing.bedrooms} Bedrooms {listing.bathrooms} Bathrooms {listing.landSize} m2</p>
                                </div>
                                <hr />

                                <div id="affordable-questions" className="flex flex-row flex-wrap gap-4 justify-between">
                                    <div>Can I afford this property?</div>
                                    <div>Calculate home loan repayments</div>
                                    <div>Fixed rate ending soon?</div>
                                </div>

                                <div id="nbn-info-box" className="flex border border-x-dom-grey-light
                                    h-[122px] w-full rounded-md justify-center items-center">
                                    NBN info here
                                </div>

                                <div id="property-description" className="flex flex-col gap-4">
                                    <h1>Property Description</h1>
                                    <p className="font-bold">{listing.title}</p>
                                    <p>{listing.description}</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <AgentDetails />
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
}