import { AxiosInstance } from "axios";
import { requests } from "stores/storeUtils";
import { ListingCreateDto, ListingDto } from "../dtos";


export class ListingAgent implements IListingAgent {
    constructor(private api: AxiosInstance) {
    }

    requests = requests(this.api);

    list(): Promise<ListingDto[]> {
        return this.requests.get<ListingDto[]>('manage/listings')
    }

    details(id: string): Promise<ListingDto> {
        return this.requests.get<ListingDto>(`manage/listings/${id}`)
    }

    create(values: ListingCreateDto): Promise<ListingDto> {
        return this.requests.post<ListingDto>('manage/listings/create', values)
    }

    update(values: ListingDto): Promise<ListingDto> {
        return this.requests.put<ListingDto>('manage/listings/update', values)
    }
    
}

export interface IListingAgent {
    list(): Promise<ListingDto[]>;
    details(id: string): Promise<ListingDto>;
    create(values: ListingCreateDto): Promise<ListingDto>;
    update(values: ListingDto): Promise<ListingDto>;
}