import React, { useEffect } from 'react'
import Slider from '@mui/material/Slider';
import { SliderControlProps } from './models';
import { toJS } from 'mobx';
import { Box } from '@mui/material';


const SimpleRangeSlider = ({ handleChange, defaults, marks }: SliderControlProps) => {

  const [value, setValue] = React.useState<number[]>([defaults[0], defaults[1]]);

  const handleInternalChange = (event: Event, newValue: number | number[]) => {

    setValue(newValue as number[])
    handleChange(newValue as number[]);

  };

  const vals = marks.length - 1;

  useEffect(() => {
    setValue(defaults as number[])
    handleChange(defaults as number[]);
  }, [defaults])


  return (
    <Box sx={{ width: '100%' }}>
        <Slider
          value={value}
          onChange={handleInternalChange}
          min={0}
          max={vals}
          sx={{
            '& .MuiSlider-thumb': {
                color: "white",
                width: "30px",
                height: "30px",
                border: "1px solid #d0d3d9"
            },
            '& .MuiSlider-track': {
                color: "#0EA800"
            },
            '& .MuiSlider-rail': {
                color: "#acc4e4"
            },
            '& .MuiSlider-active': {
                color: "green"
            }
        }}
        />
      </Box>
  );
}

export default SimpleRangeSlider