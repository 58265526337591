import { observer } from 'mobx-react-lite';
import ClearButton from './ClearButton';
import { SpaceExactModel, SpaceModel } from 'area/search/models';
import { useStore } from 'stores';
import { useEffect } from 'react';


type SpacesControlProps = {
  header: string;
  target: string;
  showClearButton?: boolean;
  spaceValues: SpaceModel[];
  spaceExactValue: SpaceExactModel;
}

const SpacesControl = ({ header, target, showClearButton, spaceValues, spaceExactValue }: SpacesControlProps) => {

  const { searchStore: {
    getDefaultStatus
  } } = useStore();


  function handleClear() {
  }


  const isDefault = getDefaultStatus(target)

  return (
    <div className='flex flex-col gap-2 p-4'>
      <div className='flex justify-between'>
        <h2 className='title-16-grey'>{header}</h2>
        {isDefault && showClearButton && <ClearButton handleClear={handleClear} />}
      </div>
      <div className='flex flex-row mx-auto items-stretch w-full'>
        {spaceValues.map((item, index) => {
          return <button onClick={item.setSelected} key={index}
            className={`py-[9px] grow border border-dom-grey-default outline-none
          ${item.selected ? "border border-dom-green-default bg-dom-green-ultralight title-16-green" : "title-16-grey"}
          `}>
            {item.label}
          </button>
        })}
      </div>
      <div className='flex gap-2 items-center mt-2'>
        <input type="checkbox" checked={spaceExactValue.value} className='form-checkbox rounded text-dom-green-default' onChange={spaceExactValue.setExact} />
        <p>Use exact values</p>
      </div>
    </div>
  )
}

export default observer(SpacesControl)