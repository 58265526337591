import { SearchListing } from 'area/search/models';
import { observer } from 'mobx-react-lite';
import ListingCard from './ListingCard';
import ListingCardSkeleton from './ListingCardSkeleton';
import { useStore } from 'stores';

interface Props {
  listings: SearchListing[];
}

function Listings({ listings }: Props) {
  const {
    searchStore: { listingsLoaded },
  } = useStore();

  const skeletons: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className='flex flex-col gap-6 sm:p-4'>
      {listingsLoaded ? (
        listings.map((listing) => (
          <ListingCard key={listing.id} listing={listing} />
        ))
      ) :
        (
          skeletons.map((x) => (
            <ListingCardSkeleton key={x} />
          ))
        )
      }
    </div>
  );
}

export default observer(Listings);
