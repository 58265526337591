import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { AccountLoginDto } from "./Account.model";
import { FieldValues, useForm } from 'react-hook-form';
import { useStore } from 'stores';


export default function LoginPage() {

    const history = useHistory();
    const location = useLocation<any>();
 
    const { accountStore, userStore } = useStore();
    
    const { register, handleSubmit, formState: { isSubmitting, errors, isValid } } = useForm({
        mode: 'all'
    });

    async function submitForm(data: FieldValues) {
        await accountStore.login(data as AccountLoginDto); 

        const user = await userStore.getCurrentUser();

        if (!user?.isUserSetupComplete) {
            history.push('/user/complete-profile');
        } else {
            history.push(location.state?.from?.pathname || '/manage');
        }
    }

    return (
        <div>
            
            <Container component={Paper} maxWidth="sm" sx={{ display: 'flex',
        flexDirection: 'column', alignItems: 'center', p: 4, mt: 2}}>
           <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
               <LockOutlinedIcon />
           </Avatar>
           <Typography component="h1" variant="h5">
               Sign in
           </Typography>
           <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
               <Box sx={{mt: 5, pt: 5}}></Box>
           </Box>
           <Box component="form" onSubmit={handleSubmit(submitForm)} noValidate sx={{ mt: 1 }}>
               <TextField
                   margin="normal"
                   fullWidth
                   label="Username"
                   autoFocus
                   {...register('email', { required: 'Email is required' })}
                   error={!!errors.username}
                   helperText={errors?.username?.message}
               />
               <TextField
                   margin="normal"
                   fullWidth
                   label="Password"
                   type="password"
                   {...register('password', { required: 'Password is required' })}
                   error={!!errors.password}
                   helperText={errors?.password?.message}
               />
               <LoadingButton
                   disabled={!isValid}
                   loading={isSubmitting}
                   type="submit"
                   fullWidth
                   variant="contained"
                   sx={{ mt: 3, mb: 2 }}
               >
                   Sign In
               </LoadingButton>
               <Grid container>
                   <Grid item>
                       <Link to='/register'>
                           {"Don't have an account? Sign Up"}
                       </Link>
                   </Grid>
               </Grid>
           </Box>
       </Container></div>
        
    )
}