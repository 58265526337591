import { observer } from 'mobx-react-lite';
import ModeControl from './ModeControl';
import { ModeEnums, SearchValueEnums } from 'area/search/models';
import PropertyTypesControl from './PropertyTypesControl';
import PriceControl from './PriceControl';
import SpacesControl from './SpacesControl';
import { useStore } from 'stores';

type FitlersControlProps = {
  header: string;
  target: string;
}

const FitlersControl = ({ header, target }: FitlersControlProps) => {

  const { searchStore: {
    priceBuyMarks, priceBuyDefault, priceRentMarks, priceRentDefault
  },
    searchHelper: {
      resetFilters, filterValues, priceBuyValue, priceRentValue,
    },
  } = useStore();

  return (
    <div className='flex flex-col gap-2 p-1'>
      <div className='flex justify-between p-3 items-center'>

        <h2 className='title-16-grey'>{header}</h2>
        <button onClick={resetFilters} className='rounded-[5px] border border-gray-300 hover:border-gray-400 px-4 py-2 text-[16px] font-medium text-dom-grey-dark hover:bg-gray-200'>Reset filters</button>
      </div>
      <div className='flex flex-col gap-3'>
        <ModeControl header='' target={SearchValueEnums.mode} />
        <hr />
        <PropertyTypesControl target={SearchValueEnums.pTypes} header='Property Types' />
        <hr />
        {filterValues.mode === ModeEnums.buy && (
          <PriceControl value={priceBuyValue} options={priceBuyMarks} defaultValue={priceBuyDefault} target={SearchValueEnums.price} header='Price' showClearButton={true} />
        )}
        {filterValues.mode === ModeEnums.rent && (
          <PriceControl value={priceRentValue} options={priceRentMarks} defaultValue={priceRentDefault} target={SearchValueEnums.price} header='Price' showClearButton={true} />
        )}
        <hr />
        <SpacesControl spaceValues={filterValues.bedRooms} spaceExactValue={filterValues.bedRoomsExact} target={SearchValueEnums.bedRooms} header='Bedrooms' />
        <hr />
        <SpacesControl spaceValues={filterValues.bathRooms} spaceExactValue={filterValues.bathRoomsExact} target={SearchValueEnums.bathRooms} header='Bathrooms' />
        <hr />
        <SpacesControl spaceValues={filterValues.parking} spaceExactValue={filterValues.parkingExact} target={SearchValueEnums.parking} header='Parking' />
      </div>
    </div>
  )
}

export default observer(FitlersControl)