import SuburbAutocomplete from './SuburbAutocomplete';
import TabButtons from '../TabButtons/TabButtons';
import SearchButton from './SearchButton';
import { useStore } from 'stores';


type Props = {
  showTabs: boolean;
  showFilterButton: boolean;
  greyedOut?: boolean,
  smallVersion?: boolean
}

export default function SuburbSearchBox({ showTabs, showFilterButton, smallVersion, greyedOut }: Props) {

  const {
    searchHelper: {
      applyFilterToStore
    }
  } = useStore();

  return (
    <div  className='flex flex-col'>
      {showTabs && <TabButtons />}
      <div id='suburbs-search-main-container' className='flex flex-row'>
        <div className={`flex flex-row relative bg-white z-200 grow`}>
          <SuburbAutocomplete
            showFilterButton={showFilterButton} smallVersion={smallVersion}
          />
        </div>
        <SearchButton handleClick={applyFilterToStore} isDisabled={false} smallVersion={smallVersion} greyedOut={greyedOut} />
      </div>
    </div>
  );
}

