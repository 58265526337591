

import { observer } from "mobx-react-lite";
import { cloneElement, useCallback, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import SignedInMenu from "./SignedInMenu";
import { useStore } from "stores";

const leftLinks = [
  // { title: "Buy", path: "/buy" },
  // { title: "Sell", path: "/sell" },
  // { title: "Rent", path: "/rent" },
];

const midLinks = [{ title: "Create Ad", path: "/manage/create-ad" }];

const rightLinks = [
  { title: "Sign up ", path: "/register" },
  { title: "Login", path: "/login" },
];

const drawerWidth = 260;


interface MenuRoute {
  name: string;
  link: string;
  activeIndex: number;
}

const routes: MenuRoute[] = [
  {
    name: "Home",
    link: "/",
    activeIndex: 0,
  },
  {
    name: "Search",
    link: "/search",
    activeIndex: 1,
  },
  {
    name: "About",
    link: "/about",
    activeIndex: 1,
  },
];



const HeaderCn = () => {


  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const {
    accountStore: { isLoggedIn, account },
  } = useStore();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [activeMenuIndex, setActiveMenuIndex] = useState<number>(0);

  const middleLinks = (
    <>
      
    </>
  );

  const path = window.location.pathname;

  const activeIndex = useCallback(() => {
    const found = routes.find(({ link }) => link === path);

    if (found) {
      setActiveMenuIndex(found.activeIndex);
    }
  }, [path]);

  useEffect(() => {
    activeIndex();
  }, [activeIndex]);

  const drawer = (
    <>
      
    </>
  );

  return (
      <></>
  );
};

export default observer(HeaderCn);
