import { ComponentType } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router';
import { toast } from 'react-toastify';
import { useStore } from 'stores';


interface Props extends RouteProps {
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
  roles?: string[];
}

export default function PrivateRoute({
  component: Component,
  roles,
  ...rest
}: Props) {

  const {
    accountStore: { account }, userStore: { user }
  } = useStore();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!account) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }

        if (!user?.isUserSetupComplete) {
          return (
            <Redirect
              to={{ pathname: '/user/complete-profile', state: { from: props.location } }}
            />
          );
        }

        // if (roles && !roles?.some((r) => account.roles?.includes(r))) {
        //   toast.error('Not authorised to access this area');
        //   return <Redirect to={{ pathname: '/catalog' }} />;
        // }

        return <Component {...props} />;
      }}
    />
  );
}
