import { Box, List, ListItem, SxProps } from "@mui/material";
import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";
import { theme } from "../theme";

const rightLinks = [
  { title: "Privacy", path: "/company/privacy" },
  { title: "Terms and conditions", path: "/company/terms-and-conditions" },
  { title: "Contact us", path: "/company/contact-us" },
];

const navStyles: SxProps = {
  whiteSpace: "nowrap",
  color: "#5C5C5C",
  textDecoration: "none",
  typography: theme.typography.body1,
  "&:hover": {
    color: "grey.500",
  },
  "&.active": {
    color: theme.palette.primary.main
  },
};

const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        minHeight: 60,
        maxHeight: 60,
        bgcolor: "background.paper",
        display: "flex",
        alignItems: "center",
        justifyContent: {
          xs: "center",
          md: "space-between",
        },
        p: 2,
        zIndex: 100
      }}
    >
      <Box sx={{ color: "#5C5C5C" }}>
        &copy; 2024 Realestateify. All rights reserved
      </Box>
      <Box sx={{ 
        color: "#5C5C5C",
        display: {
          xs: "none",
          md: "block"
        }
      }}>
        <List sx={{ display: "flex" }}>
          {rightLinks.map(({ title, path }) => (
            <ListItem
              component={NavLink}
              to={path}
              key={path}
              sx={navStyles}
            >
              {title}
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default observer(Footer);
