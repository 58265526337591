import React, { useEffect } from 'react'

type Props = {
    mainImage: string;
    images?: string[];
    getImages: () => void
}

const ListingCardImageSlider = ({mainImage, getImages, images}: Props) => {

  useEffect(() => {
    console.log(images);
    console.log('images have arrived');
  }, [images])

  return (
    <div>
      <button onClick={getImages}>Get Images</button>
        <img src={mainImage} alt="main-property-pricture" />
    </div>
  )
}

export default ListingCardImageSlider