import { makeAutoObservable, reaction, runInAction } from "mobx";
import { Account, AccountLoginDto, AccountRegisterDto } from "./Account.model";
import { toast } from "react-toastify";
import { IAccountAgent } from "./AccountAgent";
import { IRootStore } from "stores";

export default class AccountStore {
    
    account: Account | null = null;
    token: string | null = window.localStorage.getItem('token');
    fbAccessToken: string | null = null;
    fbLoading = false;
    refreshTokenTimeout: any;

    rootStore: IRootStore;

    constructor(private agent: IAccountAgent, rootStore: IRootStore) {
        makeAutoObservable(this);

        this.rootStore = rootStore;

        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem('token', token)
                } else {
                    window.localStorage.removeItem('token')
                }
            }
        );
    }

    get isLoggedIn() {
        return !!this.account;
    }

    // setToken = (token: string | null) => {
    //     this.token = token;
    // }

    login = async (creds: AccountLoginDto) => {
        try {
            
            const account = await this.agent.login(creds);
            // this.startRefreshTokenTimer(account);
            runInAction(() =>  {
                // this.setToken(account.token);
                this.account = account;
                window.localStorage.setItem('token', account.token);
                this.token = account.token;
                // window.localStorage.setItem('account', JSON.stringify(account));
            });
            this.rootStore.history.push('/');
            // await this.getUser();
        } catch (error) {
            throw error;
        }
    }

    logout = () => {
        
        runInAction(() => {
            // this.setToken(null);
            this.account = null;
            window.localStorage.removeItem('token');
            this.token = null;
            // window.localStorage.removeItem('account');
            this.rootStore.history.push('/');
        })
    }

    getcurrentAccount = async () => {
        try {

            if (!localStorage.getItem('token')) return false;
            const account = await this.agent.currentAccount();
            // store.commonStore.setToken(account.token);
            runInAction(() => {
                // this.setToken(account.token);
                // window.localStorage.setItem('token', account.token);
                this.account = account;
            });
            // this.startRefreshTokenTimer(account);
            // await this.getUser();
            return account;
        } catch (error) {
            window.localStorage.removeItem('token');
            runInAction(() => {
                // this.setToken(account.token);
                // window.localStorage.setItem('token', account.token);
                this.account = null;
            });
            toast.error('Session expired. Please login again.');
            this.rootStore.history.push('/');
        }
    }

    register = async (creds: AccountRegisterDto) => {
        try {
            await this.agent.register(creds);
            this.rootStore.history.push(`/account/registerSuccess?email=${creds.email}`);
            // store.modalStore.closeModal();
        } catch (error) {
            throw error;
        }
    }

    // setImage = (image: string) => {
    //     if (this.account) this.account.image = image;
    // } 

    // setDisplayName = (name: string) => {
    //     if (this.account) this.account.displayName = name;
    // }
    
    // refreshToken = async () => {
    //     this.stopRefreshTokenTimer();
    //     try {
    //         const account = await agent.Account.refreshToken();
    //         runInAction(() => this.account = account);
    //         // store.commonStore.setToken(account.token);
    //         this.startRefreshTokenTimer(account);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    // private startRefreshTokenTimer(account: Account) {
    //     const jwtToken = JSON.parse(atob(account.token.split('.')[1]));
    //     const expires = new Date(jwtToken.exp * 1000);
    //     const timeout = expires.getTime() - Date.now() - (60 * 1000);
    //     this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
    // }

    // private stopRefreshTokenTimer() {
    //     clearTimeout(this.refreshTokenTimeout);
    // }
}