import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Container, Box, Paper, Typography, Grid, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import AppTextInput from 'components/AppTextInput';
import { userValidationSchema } from './userValidation';
import { useStore } from 'stores';

const UserEditPage = () => {
    const router = useHistory();
    const {
        userStore: { user, getCurrentUser, updateUser },
    } = useStore();

    const {
        control,
        reset,
        handleSubmit,
        watch,
        formState: { isDirty, isSubmitting, isValid, errors },
    } = useForm({
        mode: 'all',
        resolver: yupResolver(userValidationSchema[0]),
    });

    useEffect(() => {
        if (!user) getCurrentUser();
    }, [user, getCurrentUser]);

    useEffect(() => {
        if (user && !isDirty) {
            const { rootStore, ...fields } = user;
            reset(fields);
        }
    }, [user, reset, isDirty]);

    async function handleSubmitData(data: FieldValues) {
        try {
            await updateUser(data);
            cancelEdit();
        } catch (error) {
        }
    }

    function cancelEdit() {
        router.push(`/manage/properties`);
    }

    return (
        <Container>
            <Box component={Paper} sx={{ p: 4 }}>
                <Typography variant='h6' gutterBottom sx={{ mb: 1 }}>
                    User Profile
                </Typography>
                {!user?.isUserSetupComplete && (
                    <Typography gutterBottom sx={{ mb: 3 }}>
                        Almost there! Please give us a little more info and then
                        you're all good to go!
                    </Typography>
                )}

                <form onSubmit={handleSubmit(handleSubmitData)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <AppTextInput
                                control={control}
                                name='firstName'
                                label='First Name'
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <AppTextInput
                                control={control}
                                name='lastName'
                                label='Last Name'
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <AppTextInput
                                control={control}
                                name='mobileNumber'
                                label='Mobile'
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <AppTextInput
                                control={control}
                                name='emailAddress'
                                label='Email'
                            />
                        </Grid>
                    </Grid>
                    <Box
                        display='flex'
                        justifyContent={`${user?.isUserSetupComplete === false ? 'end' : 'space-between'}` }
                        sx={{ mt: 3 }}
                    >
                        {user?.isUserSetupComplete && (<Button
                            onClick={cancelEdit}
                            variant='contained'
                            color='inherit'
                        >
                            Cancel
                        </Button>)}
                        <LoadingButton
                            loading={isSubmitting}
                            type='submit'
                            variant='contained'
                            color='success'
                            disabled={!isValid}
                        >
                            Submit
                        </LoadingButton>
                    </Box>
                </form>
            </Box>
        </Container>
    );
};

export default observer(UserEditPage);
