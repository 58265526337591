import { makeAutoObservable, runInAction } from 'mobx';
import { FieldValues } from 'react-hook-form';
import { ListingCreateMapper, ListingDto, ListingUpdateMapper } from '../dtos';
import PropertyModel from '../property/models/PropertyModel';
import ListingModel from './models/ListingModel';
import { IListingAgent } from './ListingAgent';
import { IRootStore } from 'stores';


export default class ListingStore {

    rootStore: IRootStore;
    
    status: string = 'idle';
    listingsLoaded: boolean = false;
    loadingInitial: boolean = false;

    listingsRegistry = new Map<string, ListingModel>();
    selectedListing: ListingModel | undefined = undefined;

    selectedProperty: PropertyModel | undefined;

    constructor(private agent: IListingAgent, rootStore: IRootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setSelectedProperty = (id: string) => {
        this.selectedProperty = this.rootStore.propertyStore.getProperty(id);
    }

    public loadListings = async () => {
        this.status = 'pendingLoadListings';
        this.listingsLoaded = false;
        try {
            const result = await this.agent.list();

            runInAction(() => {
                this.listingsRegistry.clear();

                result.forEach((listing) => {
                    this.mergeListingModel(listing);
                });
            });
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => {
                this.status = 'idle';
            });
            this.listingsLoaded = true;
        }
    };

    get listings() {
        return Array.from(this.listingsRegistry.values());
    }

    getListing = (id: string) => {
        return this.listingsRegistry.get(id);
    }

    mergeListingModel = (dto: ListingDto): ListingModel => {
        const item = new ListingModel(dto, this.rootStore);
        this.listingsRegistry.set(item.id, item);
        return item;
    };

    loadListing = async (id: string) => {
        let listing = this.getListing(id);
        if (listing) {
            this.selectedListing = listing;
        } else {
            this.loadingInitial = true;
            try {
                const dto = await this.agent.details(id);
                runInAction(() => {
                    const item = this.mergeListingModel(dto);
                    this.selectedListing = item;
                })
            } catch (error) {
                throw error;
            } finally {
                this.setLoadingInitial(false);
            }
        }
    }

    clearSelectedListing = () => {
        this.selectedListing = undefined;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    upsertListing = async (data: FieldValues) => {
        this.setLoadingInitial(true);
        try {
            let response: ListingDto;
            if (this.selectedListing) {
                const payload = ListingUpdateMapper(data);
                response = await this.agent.update(payload);
            } else {
                const payload = ListingCreateMapper(data);
                response = await this.agent.create(payload);
            }

            runInAction(() => {
                const item = this.mergeListingModel(response);
                this.selectedListing = item;
                
            })

        } catch (error) {
        }
    }
}
