import { Box, Typography, Pagination } from "@mui/material";
import { Metadata } from "../models/pagination";

interface Props {
    metaData: Metadata;
    onPageChange: (page: number) => void;
}

export default function AppPagination({metaData, onPageChange}: Props) {

    const {currentPage, totalItems, totalPages, itemsPerPage} = metaData;

    return (
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
            <Typography>
              Displaying {(currentPage-1)*itemsPerPage+1}-
              {currentPage*itemsPerPage > totalItems 
              ? totalItems
            : currentPage*itemsPerPage} of {totalItems} items
            </Typography>
            <Pagination 
            color='secondary' 
            size='large' 
            count={totalPages} 
            page={currentPage} 
            onChange={(e,page) => onPageChange(page)}
            />
        </Box>
    )
}