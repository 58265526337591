import { useState } from 'react'
import FilterButtonBase from './FilterButtonBase';
import PriceControl from './PriceControl';
import { observer } from 'mobx-react-lite';
import SpacesControl from './SpacesControl';
import { ModeEnums, SearchValueEnums } from 'area/search/models';
import ModeControl from './ModeControl';
import PropertyTypesControl from './PropertyTypesControl';
import FiltersControl from './FiltersControl';
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline'
import { useStore } from 'stores';


const FilterButtons = () => {

    const [openButton, setOpenButton] = useState<string>('');

    const { searchStore: {
            priceBuyMarks, priceBuyDefault, priceRentMarks, priceRentDefault, fetchLabel, getDefaultStatus, priceDefaultStatus
        }, searchHelper: {
            filterValues, priceBuyValue,  priceRentValue, filtersLoaded
        }
     } = useStore();


    if (!filtersLoaded) return <div>loading</div>

    const icon = <ChevronDownIcon className='h-5 w-5'  />
    const filtersIcon = <AdjustmentsHorizontalIcon className='h-5 w-5' />

    return (
        <div className='flex flex-row w-full gap-2 p-2'>

            <FilterButtonBase toggleOpen={setOpenButton} opened={openButton} target={"filters"} noPadding={true}
                label={'Filters'} defaultStatus={getDefaultStatus('filters')} icon={filtersIcon} iconLeft={true}>
                <FiltersControl target={"filters"} header='Filters' />
            </FilterButtonBase>

            <FilterButtonBase toggleOpen={setOpenButton} opened={openButton} target={SearchValueEnums.mode}
                label={fetchLabel(SearchValueEnums.mode)} defaultStatus={getDefaultStatus(SearchValueEnums.mode)} icon={icon}>
                <ModeControl target={SearchValueEnums.mode} header='' />
            </FilterButtonBase>

            <FilterButtonBase toggleOpen={setOpenButton} opened={openButton} target={SearchValueEnums.price}
                label={fetchLabel(SearchValueEnums.price)} defaultStatus={priceDefaultStatus} icon={icon}>
                {filterValues.mode === ModeEnums.buy && (
                    <PriceControl value={priceBuyValue} options={priceBuyMarks} defaultValue={priceBuyDefault} target={SearchValueEnums.price} header='Price' showClearButton={true} />
                )}
                {filterValues.mode === ModeEnums.rent && (
                    <PriceControl value={priceRentValue} options={priceRentMarks} defaultValue={priceRentDefault} target={SearchValueEnums.price} header='Price' showClearButton={true} />
                )}
            </FilterButtonBase>

            <FilterButtonBase toggleOpen={setOpenButton} opened={openButton} target={SearchValueEnums.bedRooms}
                label={fetchLabel(SearchValueEnums.bedRooms)} defaultStatus={getDefaultStatus(SearchValueEnums.bedRooms)} icon={icon}>
                <SpacesControl spaceValues={filterValues.bedRooms} spaceExactValue={filterValues.bedRoomsExact} target={SearchValueEnums.bedRooms} header='Bedrooms' showClearButton={true} />
            </FilterButtonBase>

            <FilterButtonBase toggleOpen={setOpenButton} opened={openButton} target={SearchValueEnums.pTypes}
                label={fetchLabel(SearchValueEnums.pTypes)} defaultStatus={getDefaultStatus(SearchValueEnums.pTypes)} icon={icon}>
                <PropertyTypesControl target={SearchValueEnums.pTypes} header='Property Types' showClearButton={true}/>
            </FilterButtonBase>

        </div>
    )
};

export default observer(FilterButtons);

export {default as PropertyTypesControl} from "./PropertyTypesControl";
export {default as SpacesControl} from "./SpacesControl";
export {default as PriceControl} from "./PriceControl";



