import { AxiosInstance } from "axios";
import { AddressMatch, Lookups, PropertyDto } from "../dtos";
import { PropertyCreateModel, PropertyUpdateModel } from "./models/PropertyModel";
import { createFormData, requests } from "stores/storeUtils";

export class PropertyAgent implements IPropertyAgent {
    constructor(private api: AxiosInstance) {
    }

    requests = requests(this.api);

    lookups(): Promise<Lookups> {
        return this.requests.get<Lookups>('manage/lookups')
    }

    list(): Promise<PropertyDto[]> {
        return this.requests.get<PropertyDto[]>('manage/properties')
    }

    details(id: string): Promise<PropertyDto> {
        return this.requests.get<PropertyDto>(`manage/properties/${id}`)
    }

    findAddress(request: string): Promise<AddressMatch[]> {
        return this.requests.get<AddressMatch[]>(`manage/address/searchAddress?request=${request}`)
    }

    create(values: PropertyCreateModel): Promise<PropertyDto> {
        return this.requests.postForm<PropertyDto>('manage/properties/create', createFormData(values))
    }

    update(values: PropertyUpdateModel): Promise<PropertyDto> {
        return this.requests.put<PropertyDto>('manage/properties/update', values)
    }
    
}

export interface IPropertyAgent {
    lookups(): Promise<Lookups>;
    list(): Promise<PropertyDto[]>;
    details(id: string): Promise<PropertyDto>;
    findAddress(request: string): Promise<AddressMatch[]>;
    create(values: PropertyCreateModel): Promise<PropertyDto>;
    update(values: PropertyUpdateModel): Promise<PropertyDto>;
}