import { makeAutoObservable, reaction, toJS } from "mobx";
import { SearchFiltersHelper } from "./SearchFiltersHelper";

export interface SearchLookups {
    features: string[];
    propertyTypes: string[];
}

export interface Postcode {
    postCode: string;
    locality: string;
    state: string;
    long: number;
    lat: number;
    id: string;
    type: string;
    status: string;
    suburb: string;
    label: string;
}

export type SuburbOption = {
    label: string;
    value: any;
    id: any;
}

export interface SearchListing {
    id: string;
    manageListingId: string;
    slug: string;
    title: string;
    address: string;
    listingRef: string;
    propertyId: string;
    dateCreated: string;
    dateListingStarts: string;
    dateListingExpires: string;
    contactEmail: string;
    contactNumber: string;
    price: number;
    listingType: string;
    isPremium: boolean;
    createdById: string;
    suburbSlug: string;
    propertyType: string;
    propertyTypeId: number;
    description: string;
    features: string[];
    bedrooms: number;
    bathrooms: number;
    parkingSpaces: number;
    landSize: number | null;
    mainImage: string;
    images: string[];
    streetNumber: string;
    streetName: string;
    postalCode: string;
    suburbOrCity: string;
    council: string;
    regionOrState: string;
    country: string;
    longitude: string;
    latitude: string;
    googleAddress: string;
}


export enum SearchValueEnums {
    mode = 'mode',
    suburbs = 'suburbs',
    pTypes = 'pTypes',
    features = 'features',
    price = 'price',
    bedRooms = 'bedRooms',
    bathRooms = 'bathRooms',
    parking = 'parking',
    propertySize = 'propertySize'
}

export interface LocationParams {
    pageNumber: string | null;
    pageSize: string | null;
    mode: string | null;
    suburbs: string | null;
    pTypes: string | null;
    features: string | null;
    price: string | null;
    bedRooms: string | null;
    bathRooms: string | null;
    parking: string | null;
    orderBy: string | null;
    searchTerm: string | null;
}

export enum FilterLabelEnums {
    Price = 'Price',
    Bedrooms = 'Bedrooms',
    Bathrooms = 'Bathrooms',
    Parking = 'Parking',
    PropertySize = 'Property Size',
    Features = 'Features',
    PropertyTypes = 'Property Types'
}

export enum ModeEnums {
    buy = 'sale',
    rent = 'rent',
    sold = 'sold'
}

export enum ModeEnumsLabels {
    sale = "Buy",
    rent = "Rent",
    sold = "Sold"
}

export enum RoomLabelEnums {
    any = 'Any',
    one = '1+',
    two = '2+'
}

export interface IRoom {
    amount: number;
}

export interface HiLowValue {
    lowValue: number;
    highValue: number;
}

export interface ISlidingValues {
    value: HiLowValue,
    target: SearchValueEnums
}

export enum PropertySizeEnums {
    Meters = 'Meters',
    Acres = 'Acres',
    Hectares = 'Hectares'
}

export enum PropertySizeLabels {
    Meters = 'metres2+',
    Acres = 'ac+',
    Hectares = 'ha+'
}

export interface SearchValues {
    page: number;
    pageSize: number;
    mode: string;
    price: HiLowValue | undefined;
    propertySize: HiLowValue | undefined;
    propertySizeType: PropertySizeEnums | undefined;
    bedRooms: number | undefined;
    bedRoomsExact: boolean;
    bathRooms: number | undefined;
    bathRoomsExact: boolean;
    parking: number;
    parkingExact: boolean;
    features: string[];
    pTypes: FeatureType[];
}

export interface FilterValues
    extends Omit<
        SearchValues,
        "page" | "pageSize" | "propertySize" | "propertySizeType" | "features"
    > {
}

export interface FilterValue {
    mode: string;
    price: HiLowValue;
    bedRooms: Array<SpaceModel>;
    bedRoomsExact: SpaceExactModel;
    bathRooms: Array<SpaceModel>;
    bathRoomsExact: SpaceExactModel;
    parking: Array<SpaceModel>;
    parkingExact: SpaceExactModel;
    pTypes: Array<CheckBoxModel>;
}


export interface SearchFilters {
    price: FilterItemDuelType;
    propertySize: FilterItemDuelType;
    bedRooms: FilterItemDuelType;
    bathRooms: FilterItemDuelType;
    parking: FilterItemDuelType;
}

export type FilterItemDuelType = {
    itemValues: HiLowValue;
    itemIndexes: HiLowValue;
    itemLabel: string;
    itemName: SearchValueEnums;
}

export type Mark = {
    value: number;
    label: string
    optionalLabel?: string;
    index?: number;
}

export type DuelValueMark = {
    lo: Mark;
    hi: Mark;
}

export interface MarksList {
    price: Mark[];
    bedRooms: Mark[];
    bathRooms: Mark[];
    parking: Mark[];
}

export interface DefaultsList {
    price: HiLowValue;
    bedRooms: number;
    bathRooms: number;
    parking: number;
    mode: string;
}

export type FeatureType = {
    label: string;
    value: string;
    parent: number | undefined;
    id: number;
    checked?: boolean;
    index?: number;
}

export class CheckBoxModel {

    id: number;
    label: string;
    value: string;
    parent: number | undefined;
    checked: boolean = false;

    constructor(dto: FeatureType) {
        makeAutoObservable(this);
        this.id = dto.id;
        this.label = dto.label;
        this.value = dto.value;
        if (dto.checked) this.checked = dto.checked;
    }

    setChecked = () => {
        this.checked = !this.checked;
    }
}

export class SpaceModel {
    value: number;
    label: string
    selected: boolean = false;

    constructor(dto: Mark, store: SearchFiltersHelper, target: string) {
        makeAutoObservable(this);
        this.value = dto.value;
        this.label = dto.label;

        reaction(() => this.selected,
            selected => {
                if (selected == true) {
                    const storeVals = store.filterValues[target] as SpaceModel[];
                    storeVals.filter(x => x.value !== this.value)
                        .map(item => item.selected = false);
                }
            }
        );

        if (target === SearchValueEnums.bedRooms) {
            reaction(() => store.filterValues.bedRoomsExact.value,
                bedRoomsExact => {
                    if (bedRoomsExact === true) {
                        this.label = dto.optionalLabel;
                    } else {
                        this.label = dto.label
                    }
                }
            );
        }

        if (target === SearchValueEnums.bathRooms) {
            reaction(() => store.filterValues.bathRoomsExact.value,
                bathRoomsExact => {
                    if (bathRoomsExact === true) {
                        this.label = dto.optionalLabel;
                    } else {
                        this.label = dto.label
                    }
                }
            );
        }

        if (target === SearchValueEnums.parking) {
            reaction(() => store.filterValues.parkingExact.value,
                parkingExact => {
                    if (parkingExact === true) {
                        this.label = dto.optionalLabel;
                    } else {
                        this.label = dto.label
                    }
                }
            );
        }


    }

    setSelected = () => {
        this.selected = !this.selected;
    }

}

export class SpaceExactModel {
    value: boolean = false;

    constructor(value: boolean) {
        makeAutoObservable(this);
        this.value = value;
    }

    setExact = () => {
        this.value = !this.value;
    }
}

export class PriceModel {
    lowMark: Mark;
    highMark: Mark;

    constructor(lowMark: Mark, highMark: Mark) {
        makeAutoObservable(this);
        this.lowMark = lowMark;
        this.highMark = highMark;
    }

    setLowValues = (mark: Mark) => {
        this.lowMark = mark;
    }

    setHighValues = (mark: Mark) => {
        this.highMark = mark;
    }
}




