import lodash from "lodash";
import { HiLowValue, Mark, SearchValues, SearchValueEnums, FeatureType, SuburbOption, Postcode, CheckBoxModel } from "./models";


export const spacesProcessor = (paramName: SearchValueEnums, searchValues: SearchValues, params: URLSearchParams, defaultValue: number, exactValues: Boolean): URLSearchParams => {

    const target = defaultValue;

    const source: number | undefined = searchValues[paramName];

    if (source == undefined) return params;

    if (exactValues) {
        params.append(paramName, `${source.toString()}`);
        return params;
    }

    if (source !== target) {

        if (exactValues) {
            params.append(paramName, `${source.toString()}`);
        } else {
            params.append(paramName, `${source.toString()}-any`);
        }

        return params;
    }

    return params;
}

export const priceProcessor = (paramName: SearchValueEnums, searchValues: SearchValues, params: URLSearchParams, defaultValues: HiLowValue): URLSearchParams => {

    let target: HiLowValue = defaultValues;


    const source = searchValues[paramName]
    if (source.lowValue !== target.lowValue || source.highValue !== target.highValue) {

        const lowVal = source.lowValue === target.lowValue ? 'any' : source.lowValue.toString();
        const highVal = source.highValue === target.highValue ? 'any' : source.highValue.toString();
        params.append(paramName, `${lowVal}-${highVal}`);

        return params;
    }

    return params;
}

export const checkboxProcessor = (paramName: SearchValueEnums, searchValues: SearchValues, params: URLSearchParams): URLSearchParams => {

    const source: CheckBoxModel[] = searchValues[paramName];
    
    if (source.length > 0) {
        const labelVals = source.map(x => x.value);
        params.append(paramName, labelVals.toString());
        return params;
    }

    return params;
}

export const suburbsProcessor = (suburbs: Postcode[], paramName: string, params: URLSearchParams): URLSearchParams => {


    if (suburbs.length > 0) {
        const labelVals = suburbs.map(x => x.suburb);
        params.append(paramName, labelVals.toString());
        return params;
    }

    return params;
}


export const stringifyValue = (value: number | string[]): string => {
    return value.toString();
}

export type MultiFilterProcessorValues = {
    numericValues: HiLowValue;
    indexValues: HiLowValue;
    label: string;
}

export const getMarkFromDollars = (marks: Mark[], value: number): Mark => {
    const output = marks.reduce((prev, curr) => Math.abs(curr.value - value) < Math.abs(prev.value - value) ? curr : prev);
    const mark = marks.find(x => x.value === output.value)!;
    return mark;
}

export const getMarkFromIndex = (marks: Mark[], index: number): Mark => {
    const mark = marks[index];
    return mark;
}

export const processPriceParams = (values: string, marks: Mark[]): number[] | undefined => {
    const split: string[] = values.split('-');
    const lowVal = split[0];
    const highVal = split[1];

    let lowValNumber = 0;
    let highValNumber = 0;


    if (lowVal === 'any') {
        const lowMarkVal = lodash.first(marks)!.value;
        lowValNumber = lowMarkVal;
    } else {
        const mark = getMarkFromDollars(marks, parseInt(lowVal));
        if (mark) {
            lowValNumber = mark.value;
        } else {
            return undefined;
        }
    }

    if (highVal === 'any') {
        const highMarkVal = lodash.last(marks)!.value;
        highValNumber = highMarkVal;
    } else {
        const mark = getMarkFromDollars(marks, parseInt(highVal));
        if (mark) {
            highValNumber = mark.value;
        } else {
            return undefined;
        }
    }

    return [lowValNumber, highValNumber]
}

export type SpaceParamsReturnDto = {
    value: number;
    exactValue: boolean;
}

export const processSpaceParams = (values: string): SpaceParamsReturnDto => {
    const split: string[] = values.split('-');

    if (split.length == 1) {
        return {
            value: parseInt(values),
            exactValue: true
        }
    } else {
        return {
            value: parseInt(split[0]),
            exactValue: false
        }
    }
}

export const processFeatureTypeParams = (values: string, options: FeatureType[]): string[] => {
    const split: string[] = values.split(',');

    const fTypes: FeatureType[] = []

    // if (split.length > 0) {
    //     split.forEach(fType => {
    //         const item = options.find(x => x.value == fType);
    //         if (item) {
    //             fTypes.push(item);
    //         }
    //     })
    //     return fTypes;
    // }

    return split;
}

export const processSuburbsParams = (values: string): string[] => {
    const split: string[] = values.split(',');
    return split;
}

interface HiLowProcessorProps {
    values: HiLowValue,
    defaults: HiLowValue,
    defaultLabel: string,
    marks: Mark[],
    fromIndex: boolean
}

export const hiLowLabelProcessor = ({ values, defaults, defaultLabel, marks, fromIndex }: HiLowProcessorProps): string => {

    let returnLabel: string = defaultLabel;

    if (values == undefined) return returnLabel;

    let lowMark: Mark;
    let highMark: Mark;

    if (fromIndex) {
        lowMark = marks[values.lowValue];
        highMark = marks[values.highValue]
    } else {
        lowMark = getMarkFromDollars(marks, values.lowValue)!;
        highMark = getMarkFromDollars(marks, values.highValue)!;
    }

    if (lowMark.value !== defaults.lowValue && highMark.value === defaults.highValue) {
        returnLabel = `Over ${lowMark.label}`
    }

    if (lowMark.value === defaults.lowValue && highMark.value !== defaults.highValue) {
        returnLabel = `Under ${highMark.label}`
    }

    if (lowMark.value !== defaults.lowValue && highMark.value !== defaults.highValue) {
        returnLabel = `${lowMark.label} - ${highMark.label}`
    }

    return returnLabel;
}

