import { Footer, MainNavBar, OtherLinksBar } from 'layout'
import { observer } from 'mobx-react-lite'
import { FullPageSearch, SuburbSearchBox } from 'area/search/components'
import { ClockIcon } from '@heroicons/react/24/outline'
import { RecentSearchButton, WhileYourHere, DreamHomes, FoundForYou, LatestNews, MobilePhoneApp } from './components'
import { useStore } from 'stores'

const HomePage = () => {

    const {
        searchStore: {
            setToggleSearchPage,
            toggleSearchPage
        },
    } = useStore();

    return (
        <div>
            <FullPageSearch handleToggle={() => setToggleSearchPage()} toggle={toggleSearchPage} />
            <div id='hero-area'>
                <div
                    style={{
                        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.0), rgba(81, 91, 110, 0.6)), url(/homepage-12-optimised-desktop.jpg) no-repeat",
                        width: "100%",
                        height: "355px",
                        objectFit: "cover",
                        position: "absolute",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                >
                    <div className='hidden lg:visible lg:block'>
                        <OtherLinksBar />
                    </div>

                    <MainNavBar lightMode={true} />

                    <div className='flex-col mx-auto justify-center max-w-[1000px] items-center h-auto px-4 lg:px-0'>

                        <div className=' flex justify-center items-center mb-6'>
                            <h1 className='font-sans font-bold text-white text-[23px] md:text-[28px] lg:text-[31px]'>Know your next move</h1>
                        </div>

                        <div className='flex justify-center w-full items-center text-center drop-shadow-md'>
                            <div className='bg-white rounded-md w-full py-[36px] px-[60px]' data-testid='home-page-search-box'>
                                <div className='flex flex-col w-full gap-1'>
                                    <SuburbSearchBox showTabs={true} showFilterButton={true} smallVersion={false} />

                                    <div className='flex flex-col md:flex-row gap-3 justify-between mt-4'>

                                        <div id='recent-searches' className='grow flex flex-col justify-items-start gap-3'>
                                            <div className='flex text-dom-grey-default gap-2 font-medium items-center'>
                                                <ClockIcon className="h-6 w-6" />
                                                <p className='uppercase'>recent searches</p>
                                            </div>
                                            <div className='flex flex-col text-left sm:flex-row gap-3 justify-between'>
                                                <RecentSearchButton title='Runcorn' label='All Listings' itemCount='1' />
                                                <RecentSearchButton title='All Listings' label='' itemCount='99+' />
                                                <RecentSearchButton title='Eight Mile Plains' label='All Listings' itemCount='26' />
                                            </div>
                                        </div>

                                        <div id='domain-home-loans-box' className='border-l md:border-dom-grey-light md:pl-4'>
                                            <div className='flex justify-between md:flex-col p-3 bg-dom-green-ultralight border border-dom-green-default rounded-md items-start md:w-[194px] gap-1 cursor-pointer'>
                                                <div className='flex-col'>
                                                    <p className='font-sans text-sm font-medium text-dom-green-hover text-left'>Domain Home Loans</p>
                                                    <p className='font-sans text-sm font-bold text-left'>Find and apply for a competitive home loan</p>
                                                </div>
                                                <p className='font-sans text-base font-bold text-dom-green-default mt-1 whitespace-nowrap'>Get started &gt;</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='mt-10 h-[313px] bg-slate-100'>
                            <DreamHomes />
                        </div>

                        <div className='mt-4 h-[313px]  bg-slate-100'>
                            <FoundForYou />
                        </div>

                        <div className='mt-4 h-[150px]  bg-slate-100'>
                            <WhileYourHere />
                        </div>

                        <div className='mt-4 h-[150px]  bg-slate-100'>
                            <LatestNews />
                        </div>

                        <div className='mt-4 h-[150px]  bg-slate-100'>
                            <MobilePhoneApp />
                        </div>

                    </div>

                    <Footer />


                </div>
            </div>
        </div>
    )
}

export default observer(HomePage);