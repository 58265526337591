import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
// import { history } from 'index';
import { PaginatedResults } from '../models/pagination';
import { Account } from 'area/account/Account.model';
import { AddressMatch, ListingCreateDto, ListingDto, Lookups, PropertyDto } from 'area/manage/dtos';
import { PaymentIntentDto } from 'area/manage/payment/dtos';
import { PropertyCreateModel, PropertyUpdateModel } from 'area/manage/property/models/PropertyModel';
import { Postcode, SearchListing, SearchLookups } from 'area/search/models';
import { UserDto } from 'area/user/dtos';
import { rootStore } from 'stores';



axios.defaults.baseURL = process.env.REACT_APP_API_URL + "/";

const responseBody = <T> (response: AxiosResponse<T>) => response.data;

const sleep = () => new Promise(resolve => setTimeout(resolve, 0));

axios.interceptors.request.use((config) => {
    const token = rootStore.accountStore.token;
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  });
  
  axios.interceptors.response.use(
    async (response) => {
      await sleep();
      const pagination = response.headers['pagination'];
      if (pagination) {
        response.data = new PaginatedResults(response.data.items, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResults<any>>
      }
      return response;
    },
    (error: AxiosError) => {
  
      const { data, status } = error.response!;
      
      switch (status) {
        case 400:
          if (data.errors) {
            const modelStateErrors: string[] = [];
            for (const key in data.errors) {
              if (data.errors[key]) {
                modelStateErrors.push(data.errors[key]);
              }
            }
            throw modelStateErrors.flat();
          }
          toast.error(data.title);
          break;
        case 401:
          toast.error(data.title || data);
          break;
        case 500:
          // rootStore.history.push({
          //   pathname: 'server-error',
          //   state: { error: data },
          // });
          break;
  
        default:
          break;
      }
      return Promise.reject(error.response);
    }
  );

const requests = {
    get: <T> (url: string) => axios.get<T>(url).then(responseBody),
    post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    delete: <T> (url: string) => axios.delete<T>(url).then(responseBody),
    postForm: <T> (url: string, data: FormData) => axios.post<T>(url, data, {
      headers: {'Content-type': 'multipart/form-data'}
    }).then(responseBody),
    putForm: <T> (url: string, data: FormData) => axios.put<T>(url, data, {
      headers: {'Content-type': 'multipart/form-data'}
    }).then(responseBody)
}

const Accounts = {
    login: (values: any) => requests.post<Account>('account/login', values),
    register: (values: any) =>
      requests.post<Account>('account/register', values),
    currentAccount: () => requests.get<Account>('account/me'),
  };

const Users = {
  currentUser: () => requests.get<UserDto>('users/me'),
  update: (values: UserDto) => requests.put<UserDto>('users/update', values)
};

const Search = {
    find: (params: URLSearchParams) => axios.get<PaginatedResults<SearchListing[]>>('search/listings', {params}).then(responseBody),
    details: (id: string) => requests.get<SearchListing>(`search/listings/${id}`),
    filters: () => requests.get<SearchLookups>('search/lookups'),
    findPostcode: (request: string) => requests.get<Postcode[]>(`search/postcodes?request=${request}`),
}

function createFormData(item: any) {
  let formData = new FormData();
  for (const key in item) {
      formData.append(key, item[key])
  }
  return formData;
}

const Properties = {
  lookups: () => requests.get<Lookups>('manage/lookups'),
  list: () => requests.get<PropertyDto[]>('manage/properties'),
  details: (id: string) => requests.get<PropertyDto>(`manage/properties/${id}`),
  findAddress: (request: string) => requests.get<AddressMatch[]>(`manage/address/searchAddress?request=${request}`),
  create: (values: PropertyCreateModel) => requests.postForm<PropertyDto>('manage/properties/create', createFormData(values)),
  update: (values: PropertyUpdateModel) => requests.put<PropertyDto>('manage/properties/update', values),
};

const Listings = {
  list: () => requests.get<ListingDto[]>('manage/listings'),
  details: (id: string) => requests.get<ListingDto>(`manage/listings/${id}`),
  create: (values: ListingCreateDto) => requests.post<ListingDto>('manage/listings/create', values),
  update: (values: ListingDto) => requests.put<ListingDto>('manage/listings/update', values)
};

const Payments = {
  getPaymentIntent: (listingId: string) => requests.post<PaymentIntentDto>('manage/payments/getPaymentIntent', {id: listingId}),
  setupIntent: () => requests.post("stripe/setupIntent", {}),
  addPaymentMethod: (paymentMethodId: string) => requests.post<void>("stripe/addPaymentMethod", {id: paymentMethodId}),
  // getPaymentMethods: (): Promise<IPaymentMethod[]> => requests.get<IPaymentMethod[]>("/stripe/getPaymentMethods"),
  // createPaymentIntent: (priceId: string, paymentMethodId: string) => requests.post("/stripe/createPaymentIntent", {priceId, paymentMethodId})
}

const TestErrors = {
    get400Error: () => requests.get('buggy/bad-request'),
    get401Error: () => requests.get('buggy/get-unauthorised'),
    get404Error: () => requests.get('buggy/not-found'),
    get500Error: () => requests.get('buggy/server-error'),
    getValidationError: () => requests.get('buggy/validation-error'),
  };

const agent = {
    Accounts,
    Users,
    Search,
    Properties,
    Listings,
    Payments,
    TestErrors
}

export default agent;