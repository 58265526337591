import { useState, useEffect } from 'react';

export const ScreenSizes = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280
}

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const smallScreen:boolean = screenSize.width <= ScreenSizes.sm ? true : false;
  const mediumScreen:boolean = screenSize.width >= ScreenSizes.md ? true : false;
  const largeScreen:boolean = screenSize.width >= ScreenSizes.lg ? true : false;
  const xlScreen:boolean = screenSize.width >= ScreenSizes.xl ? true : false;

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    screenSize,
    smallScreen,
    mediumScreen,
    largeScreen,
    xlScreen
  }
};

export default useScreenSize;



