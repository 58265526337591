import { makeAutoObservable, runInAction } from "mobx";
import { Metadata } from "models/pagination";
import { Feature, PropertyType } from "../dtos";
import PropertyModel, {
  PropertyCreateModel,
  PropertyCreateValues,
  PropertyUpdateModel,
  PropertyUpdateValues,
} from "./models/PropertyModel";
import { IPropertyAgent } from "./PropertyAgent";
import { IRootStore } from "stores";

export default class PropertyStore {
  rootStore: IRootStore;
  status: string = "idle";
  features: Feature[] = [];
  propertyTypes: PropertyType[] = [];
  lookupsLoaded: boolean = false;
  propertiesLoaded: boolean = false;
  loadingInitial = false;

  pagination: Metadata | null = null;

  propertiesRegistry = new Map<string, PropertyModel>();
  selectedProperty: PropertyModel | undefined = undefined;

  addressesLoaded: boolean = false;

  constructor(private agent: IPropertyAgent, rootStore: IRootStore) {
    makeAutoObservable(this);

    this.rootStore = rootStore;
  }

  get propertyImages() {
    const image1 = `${process.env.PUBLIC_URL}/assets/carouselImage1.jpg`;
    const image2 = `${process.env.PUBLIC_URL}/assets/carouselImage2.jpg`;
    const image3 = `${process.env.PUBLIC_URL}/assets/carouselImage3.jpg`;
    const image4 = `${process.env.PUBLIC_URL}/assets/carouselImage4.jpg`;
    const image5 = `${process.env.PUBLIC_URL}/assets/carouselImage5.jpg`;

    return [image1, image2, image3, image4, image5];
  }

  loadProperties = async (): Promise<PropertyModel[]> => {
    this.status = "pendingSearch";
    this.propertiesLoaded = false;
    let results: PropertyModel[] = [];
    try {
      const dtos = await this.agent.list();

      runInAction(() => {
        this.propertiesRegistry.clear();
        results = dtos.map((dto) => new PropertyModel(dto, this.rootStore));
        results.map((result) => this.mergePropertyModel(result));
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.status = "idle";
        this.propertiesLoaded = true;
      });
      return results;
    }
  };

  get properties() {
    return Array.from(this.propertiesRegistry.values());
  }

  getProperty = (id: string): PropertyModel | undefined => {
    return this.propertiesRegistry.get(id);
  };

  public mergePropertyModel = (property: PropertyModel) => {
    this.propertiesRegistry.set(property.id, property);
  };

  triggerReloadProperties = () => {
    runInAction(() => {
      this.propertiesLoaded = false;
    });
  };

  loadProperty = async (id: string) => {
    let property = this.getProperty(id);
    if (property) {
      this.selectedProperty = property;
    } else {
      this.loadingInitial = true;
      try {
        const dto = await this.agent.details(id);
        runInAction(() => {
          if (dto) {
            property = new PropertyModel(dto, this.rootStore);
            this.mergePropertyModel(property);
            this.selectedProperty = property;
          }
        });
      } catch (error) {
      } finally {
        this.setLoadingInitial(false);
        return property;
      }
    }
  };

  clearSelectedProperty = () => {
    this.selectedProperty = undefined;
  };

  setSelectedProperty = (id: string) => {
    return (this.selectedProperty = this.getProperty(id));
  };

  setCreatedProperty = () => {};

  loadLookups = async () => {
    this.status = "pendingLookups";
    this.lookupsLoaded = false;
    try {
      const lookups = await this.agent.lookups();

      runInAction(() => {
        this.features = lookups.features;
        this.propertyTypes = lookups.propertyTypes;
        this.lookupsLoaded = true;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.status = "idle";
      });
    }
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  searchAddress = async (query: string) => {
    this.status = "pendingAddress";
    this.addressesLoaded = false;
    try {
      const result = await this.agent.findAddress(query);
      runInAction(() => {
        this.addressesLoaded = true;
      });
      return result;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.status = "idle";
      });
    }
  };

  public createProperty = async (item: PropertyCreateModel) => {
    try {
      const result = await this.agent.create(item);
      return result;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.status = "idle";
      });
    }
  };

  public updateProperty = async (item: PropertyUpdateModel) => {
    try {
        const result = await this.agent.update(item);
        return result;
      } catch (error) {
        throw error;
      } finally {
        runInAction(() => {
          this.status = "idle";
        });
      }
  };

  // upsertProperty = async (data: FieldValues) => {

  //     let property = this.getProperty(data.id);
  //     this.status = 'updatingProperty';
  //     this.loadingInitial = true;
  //         try {
  //             const dto = await this.agent.update(model);
  //             runInAction(() => {
  //                 if (dto) {
  //                     property = new PropertyModel(dto, this.rootStore);
  //                     this.mergePropertyModel(property);
  //                     this.selectedProperty = property;
  //                 }
  //             })
  //         } catch (error) {
  //             console.log(error);
  //         } finally {
  //             this.setLoadingInitial(false);
  //             return property;
  //         }

  // }
}
