import { Button, Container, Divider, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function NotFound(){

    return (
        <Container component={Paper}>
            <Typography variant='h3' sx={{height: 200}}>
                Oops - we could not find what you are looking for
            </Typography>
            <Divider />
            <Button fullWidth component={Link} to='/search'>Go back to Search</Button>
        </Container>
    )
}