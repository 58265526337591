import { observer } from 'mobx-react-lite'
import { MenuLink } from 'stores/RouterStore'

type TabButtonProps = {
  link: MenuLink,
  isActive: boolean
}

const TabButton = ({link}: TabButtonProps) => {

  return (
    <div onClick={link.setActiveChild}
    data-testid={link.title}
    className={`font-sans whitespace-nowrap font-semibold text-[16px] rounded-t-md cursor-pointer px-[22px] py-[15px] ${link.activeState ? "bg-dom-green-default text-white": "text-dom-grey-dark  hover:text-dom-green-hover"}`}>
      {link.title}
    </div>
  )
}

export default observer(TabButton)