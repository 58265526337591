import { FieldValues } from "react-hook-form";

export interface UserDto {
    id: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    emailAddress: string;
    fullName: string;
    isUserSetupComplete: boolean;
}

export function UserUpdateMapper(data: FieldValues): UserDto {
    const mappedData = {
        ...data
    } as UserDto;
    return mappedData;
}