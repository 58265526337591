import PrivateRoute from 'layout/PrivateRoute'
import { Switch } from 'react-router-dom'
// import PropertyListPage from "area/manage/property/PropertyListPage";
// import PropertyEditForm from './property/PropertyEditForm';
// import UserEditPage from 'area/user/UserEditPage';
// import ListingListPage from './listing/ListingListPage';
// import ListingWrapper from './listing/ListingWrapper';
// import ListingReviewPage from './listing/details/ListingReviewPage';
// import CreateAdWizardPage from './listing/wizard/CreateAdWizardPage';
// import CheckoutPage from './payment/CheckoutPage';
// import PaymentDiaglogPage from './payment/PaymentDiaglogPage';
// import PropertyReviewPage from './property/details/PropertyReviewPage';


import { Header, ManageHome } from './components';


const ManagePageCn = () => {
  return (
    <>
        <Header />
        <div>

          <Switch>

          <PrivateRoute
              exact
              path="/manage"
              component={ManageHome}
            />

            {/* <PrivateRoute
              exact
              path="/user/update-profile"
              component={UserEditPage}
            />

            <PrivateRoute
              exact
              path="/manage/properties"
              component={PropertyListPage}
            />
            <PrivateRoute
              exact
              path="/manage/properties/:id/edit"
              component={PropertyEditForm}
            />
            <PrivateRoute
              exact
              path="/manage/properties/:id/view"
              component={PropertyReviewPage}
            />
            <PrivateRoute
              exact
              path="/manage/listings/:id/payment"
              component={CheckoutPage}
            />
            <PrivateRoute
              exact
              path="/manage/properties/add"
              component={PropertyEditForm}
            />
            <PrivateRoute
              exact
              path="/manage/listings"
              component={ListingListPage}
            />
            <PrivateRoute
              exact
              path="/manage/listings/:id/edit"
              component={ListingWrapper}
            />
            <PrivateRoute
              exact
              path="/manage/listings/:id/view"
              component={ListingReviewPage}
            />
            <PrivateRoute
              exact
              path="/manage/listings/add"
              component={ListingWrapper}
            />
            <PrivateRoute
              exact
              path="/manage/create-ad"
              component={CreateAdWizardPage}
            />
            <PrivateRoute
              exact
              path="/manage/checkout"
              component={PaymentDiaglogPage}
            /> */}

          </Switch>
        </div>
    </>
  )
}

export default ManagePageCn