import { AxiosInstance } from "axios";
import { UserDto } from "./dtos";
import { requests } from "stores/storeUtils";

export class UserAgent implements IUserAgent {
    constructor(private api: AxiosInstance) {
    }

    requests = requests(this.api);

    currentUser(): Promise<UserDto> {
        return this.requests.get<UserDto>('users/me')
    }    

    update(values: UserDto): Promise<UserDto> {
        return this.requests.put<UserDto>('users/update', values)
    }
}

export interface IUserAgent {
    currentUser(): Promise<UserDto>;
    update(values: UserDto): Promise<UserDto>;
}
