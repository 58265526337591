import { makeAutoObservable } from 'mobx';
import { FieldValues } from 'react-hook-form';
import {  AddressMatch, LocationDto, PropertyCreateDto, PropertyDto, PropertyUpdateDto } from '../../dtos';
import AddressMatchModel from './AddressMatchModel';
import { IRootStore } from 'stores';

export default class PropertyModel implements PropertyDto {
    rootStore: IRootStore;

    id: string;
    createdById: string;
    created: Date;
    name: string;
    slug: string;
    suburbSlug: string;
    mainImage: string;
    location?: LocationDto;
    listingType?: string;
    propertyTypeId: number;
    description: string;
    bedrooms: number;
    bathrooms: number;
    parkingSpaces: number;
    landSize: number;
    address: AddressMatch;

    constructor(dto: PropertyDto, rootStore: IRootStore) {

        makeAutoObservable(this);
        this.rootStore = rootStore;

        this.id = dto.id ?? '';
        this.createdById = dto.createdById;
        this.created = dto.created;
        this.name = dto.name;
        this.slug = dto.slug;
        this.suburbSlug = dto.suburbSlug;
        this.mainImage = dto.mainImage;
        this.location = dto.location;
        this.listingType = dto.listingType;
        this.propertyTypeId = dto.propertyTypeId;
        this.description = dto.description;
        this.bedrooms = dto.bedrooms;
        this.bathrooms = dto.bathrooms;
        this.parkingSpaces = dto.parkingSpaces;
        this.landSize = dto.landSize;
        this.address = dto.address;


        // this.address = new AddressMatchModel(dto.address);
        
        // if (dto.location) {
        //     this.location = new LocationModel(dto.location);
        // } else {
        //     this.location = undefined;
        // }
    }

    // private addProperty = (property: PropertyDto) => {
    //   this.rootStore.propertyStore.addProperty(x, this.id);
    // }
}


export interface PropertyCreateValues extends FieldValues {
    propertyTypeId: number;
    description: string;
    bedrooms: number;
    bathrooms: number;
    parkingSpaces: number;
    landSize: number;
    address:AddressMatch;
    file: File
 }

export class PropertyCreateModel implements PropertyCreateDto  {

    propertyTypeId: number;
    description: string;
    bedrooms: number;
    bathrooms: number;
    parkingSpaces: number;
    landSize: number;
    addressDescription: string;
    addressId: string;
    file: File;

    constructor(data: PropertyCreateValues) {
        this.addressId = data.address.id;
        this.addressDescription = data.address.description;
        this.bathrooms = data.bathrooms;
        this.bedrooms = data.bedrooms;
        this.description = data.description;
        this.landSize = data.landSize;
        this.parkingSpaces = data.parkingSpaces;
        this.propertyTypeId = data.propertyTypeId;
        this.file = data.file;
    }
}

export interface PropertyUpdateValues extends FieldValues, PropertyUpdateDto {
    id: string;
    propertyTypeId: number;
    description: string;
    bedrooms: number;
    bathrooms: number;
    parkingSpaces: number;
    landSize: number;
    address:AddressMatch;
}

export class PropertyUpdateModel implements PropertyUpdateDto {

    id: string;
    propertyTypeId: number;
    description: string;
    bedrooms: number;
    bathrooms: number;
    parkingSpaces: number;
    landSize: number;
    addressDescription: string;
    addressId: string;

    constructor(data: PropertyUpdateValues) {
        this.id = data.id;
        this.addressId = data.address.id;
        this.addressDescription = data.address.description;
        this.bathrooms = data.bathrooms;
        this.bedrooms = data.bedrooms;
        this.description = data.description;
        this.landSize = data.landSize;
        this.parkingSpaces = data.parkingSpaces;
        this.propertyTypeId = data.propertyTypeId;
    }
}
