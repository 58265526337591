import { makeAutoObservable } from "mobx";
import { IRootStore } from "stores";
import { UserDto } from "../dtos";

export default class UserModel implements UserDto {
    rootStore: IRootStore;

    id: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    fullName: string;
    isUserSetupComplete: boolean;


    constructor(dto: UserDto, rootStore: IRootStore) {

        makeAutoObservable(this);
        this.rootStore = rootStore;

        this.id = dto.id ?? '';
        this.emailAddress = dto.emailAddress ?? '';
        this.firstName = dto.firstName ?? '';
        this.lastName = dto.lastName ?? '';
        this.mobileNumber = dto.mobileNumber ?? '';
        this.fullName = dto.fullName ?? '';
        this.isUserSetupComplete = dto.isUserSetupComplete;
    }
}