import {
    Dialog,
    DialogContent,
} from '@mui/material';
import UserEditPage from './UserEditPage';
import { useStore } from 'stores';

const UserProfileWrapper = () => {
    const {
        userStore: { user },
    } = useStore();

    return (
        <Dialog fullScreen open={true}>
            <DialogContent sx={{ marginTop: '4em' }}>
                <UserEditPage />
            </DialogContent>
        </Dialog>
    );
};

export default UserProfileWrapper;
