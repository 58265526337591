import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react'
import ClearButton from './ClearButton';
import { useStore } from 'stores';

type PropertyTypesControlProps = {
  header: string;
  target: string;
  showClearButton?: boolean;
  buttonStyle?: boolean;
}

const PropertyTypesControl = ({ header, target, showClearButton, buttonStyle }: PropertyTypesControlProps) => {

  const { searchStore: {
    getDefaultStatus
  }, searchHelper: {
    propertyTypes, propertyTypesChecked, clearPropertyTypes
  }
  } = useStore();

  const [allCheckBox, setAllCheckBox] = useState<boolean>(false);

  const handleAllCheckbox = () => {
    if (allCheckBox == false) {
      setAllCheckBox(true);
      clearPropertyTypes();
    }
    if (allCheckBox == true) {
      setAllCheckBox(false);
      if (!propertyTypesChecked) {
        propertyTypes[0].setChecked();
      }
    }
  }

  function handleClear() {
    clearPropertyTypes();
  }

  useEffect(() => {
    if (propertyTypesChecked) {
      setAllCheckBox(false)
    } else {
      setAllCheckBox(true)
    }
  }, [propertyTypesChecked])

  const isDefault = getDefaultStatus(target)

  const checkBoxes = <div className='flex flex-col gap-3'>
    <div className='flex flex-row gap-3 items-center'>
      <input type="checkbox" checked={allCheckBox} className='form-checkbox text-dom-green-default' onChange={handleAllCheckbox} />
      <p>All</p>
    </div>
    {propertyTypes && propertyTypes.map((item) => (
      <div key={item.id} className='flex flex-row gap-3 items-center'>
        <input type="checkbox" checked={item.checked} className='form-checkbox text-dom-green-default' onChange={item.setChecked} />
        <p>{item.label}</p>
      </div>
    ))}
  </div>;

  const buttons = <div className='flex flex-row gap-3 flex-wrap'>
    <button className={`${allCheckBox ? "btn-standard-selected" : "btn-standard"}`} onClick={handleAllCheckbox}>All</button>
    {propertyTypes && propertyTypes.map((item) => (
      <div key={item.id} className='flex flex-row gap-3 items-center'>
        <button className={`${item.checked ? "btn-standard-selected" : "btn-standard"}`} onClick={item.setChecked}>{item.label}</button>
      </div>
    ))}
  </div>;

  return (
    <div className='flex flex-col gap-2 p-4'>
      <div className='flex justify-between'>
        <h2 className='title-16-grey'>{header}</h2>
        {isDefault && showClearButton && <ClearButton handleClear={handleClear} />}
      </div>
      {buttonStyle ? (
        buttons
      ) : (
        checkBoxes
      )}

    </div>
  )
}

export default observer(PropertyTypesControl)