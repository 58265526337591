import { makeAutoObservable, runInAction } from "mobx";
import { ModeEnums } from "area/search/models";
import { IRootStore } from "stores";


const ROUTER_PUSH: boolean = false;

export interface MenuLinkDto {
    title: string,
    path: string,
    isTab?: boolean,
    isTabOnly?: boolean,
    megaMenu?: MegaMenuDto[],
    childlinks?: MenuLinkDto[]
}

export interface MegaMenuDto {
    header: string,
    links: MenuLinkDto[]
}

export const menuData:MenuLinkDto[] = [
    {
        title: 'Find a Property',
        path: '#',
        childlinks: [
            {
                title: "Buy",
                path: "/",
                isTab: true
            },
            {
                title: "Rent",
                path: "/rent",
                isTab: true
            },
            {
                title: "House & Land",
                path: "/house-and-land",
                isTab: true
            },
            {
                title: "New Homes",
                path: "/new-homes",
                isTab: true
            },
            {
                title: "Sold",
                path: "/sold",
                isTab: true,
                isTabOnly: true
            },
            {
                title: "Retirement",
                path: "/retirement",
                isTab: true,
                isTabOnly: true
            },
            {
                title: "Rural",
                path: "/rural",
                isTab: true
            },
            {
                title: "Share",
                path: "/share"
            },
            {
                title: "Search by school",
                path: "/schools"
            }
        ]
    },
    {
        title: 'Research',
        path: '#',
        megaMenu: [
            {
                header: 'Property Market Insights',
                links: [
                    {
                        title: 'Property Price Estimates',
                        path: '#'
                    },{
                        title: 'Suburb Profiles',
                        path: '#'
                    },{
                        title: 'Property Market Analysis',
                        path: '#'
                    },{
                        title: 'Auction Results',
                        path: '#'
                    },{
                        title: 'Sold Properties',
                        path: '#'
                    },{
                        title: 'Find Affordable Suburbs',
                        path: '#'
                    },
                ]
            },
            {
                header: 'Guides',
                links: [
                    {
                        title: 'Advice',
                        path: '#'
                    },{
                        title: 'Buying',
                        path: '#'
                    },{
                        title: 'Investing',
                        path: '#'
                    },{
                        title: 'Renting',
                        path: '#'
                    },
                ]
            }
        ]
    },
    {
        title: 'Find Agents',
        path: '/real-estate-agents'
    },
    {
        title: 'For Owners',
        path: '#',
        childlinks: [
            {
                title: 'My Properties',
                path: '#'
            }
        ]
    },
    {
        title: 'Home Loans',
        path: '#',
        childlinks: [
            {
                title: 'Refinance Online',
                path: '#'
            }
        ]
    },
    {
        title: 'News',
        path: '#',
        childlinks: [
            {
                title: 'Property News',
                path: '#'
            }
        ]
    },
    {
        title: 'Commercial',
        path: '#'
    }
]

let runningId = 0;

const getNextId = (): string => {
    const id = runningId++;
    return id.toString();
}

export class MegaMenu {

    routerStore: RouterStore;

    id: string = getNextId();

    header: string = '';

    isActive: boolean = false;

    childlinks: MenuLink[] = [];

    constructor(item: MegaMenuDto, routerStore: RouterStore) {
        this.routerStore = routerStore;
        makeAutoObservable(this)
        this.header = item.header;
        this.setChildLinks(item.links)
    }

    setChildLinks(items: MenuLinkDto[]){
        items.forEach(item => {
            this.childlinks.push(new MenuLink(item, this.routerStore));
        });
    }
}

export class MenuLink {
    id: string = getNextId();

    routerStore: RouterStore;

    isActive: boolean;

    isTab?: boolean;

    isTabOnly?: boolean;

    title: string;

    path: string;

    megaMenus: MegaMenu[] = [];

    childlinks: MenuLink[] = [];

    constructor(item: MenuLinkDto, routerStore: RouterStore) {
        this.routerStore = routerStore;
        makeAutoObservable(this)
        this.title = item.title;
        this.path = item.path;
        this.isActive = false;
        this.isTab = item.isTab;
        this.isTabOnly = item.isTabOnly;

        if (item.childlinks) {
            this.setChildLinks(item.childlinks);
        }

        if (item.megaMenu){
            this.setMegaMenu(item.megaMenu);
        }

    }

    setActive = () => {
        runInAction(() => {
            this.routerStore.setCurrentLink(this);
        })
    }

    setActiveChild = () => {
        runInAction(() => {
            this.routerStore.setCurrentChildLink(this);
            this.routerStore.setCloseMenu()
            this.setInactive()
        })
    }

    setInactive = () => {
        runInAction(() => {
            this.routerStore.setCurrentLink(null);
        })
    }

    setChildLinks(items: MenuLinkDto[]){
        items.forEach(item => {
            this.childlinks.push(new MenuLink(item, this.routerStore));
        });
    }

    setMegaMenu(items: MegaMenuDto[]){
        items.forEach(item => {
            this.megaMenus.push(new MegaMenu(item, this.routerStore));
        });
    }

    get activeState():boolean {
        return this.id === this.routerStore.activeLink?.id ||
                this.id === this.routerStore.activeChildLink?.id;
    }

    get hasChildren():boolean {
        return this.childlinks.length !== 0 || this.megaMenus.length !== 0;
    }

    get hasMegaMenu():boolean {
        return this.megaMenus.length !== 0;
    }


}

export default class RouterStore  {

    rootStore: IRootStore;

    parentLink: MenuLink | undefined;

    openMenu: boolean;

    links: MenuLink[] = [];

    currentLink: MenuLink | null;

    currentChildLink: MenuLink | null;

    constructor(rootStore: IRootStore){
        
        makeAutoObservable(this)

        this.rootStore = rootStore;

        this.openMenu = false;
        
        menuData.forEach((item) => {
            const link = new MenuLink(item, this);
            this.setLink(link);
        })

        this.currentLink = null;
        this.currentChildLink = null;

        this.parentLink = this.links.find(x => x.title === 'Find a Property');

        // Set the Buy Tab to be the default selected tab on app loadup
        this.setHomeTab();

    }

    private setLink = (link: MenuLink) => {
        this.links.push(link);
    };

    setHomeTab =() => {
        const tablinks = this.links[0].childlinks.filter(x => x.isTab === true);
        this.setCurrentChildLink(tablinks[0]);
    }

    setCurrentLink = (link: MenuLink | null) => {

        if (link) {
            this.currentLink = link;

            if (link?.path !== '#' && ROUTER_PUSH) {
                this.rootStore.history.push(link!.path)
            }
        } else {
            this.currentLink = null;
        }
        
    }

    setCurrentChildLink = (link: MenuLink | null) => {
        
        if (link) {

            this.currentChildLink = link;

            if (this.rootStore) {
                if (link.title == "Buy") {
                    this.rootStore.searchStore.setMode(ModeEnums.buy, true)
                }
        
                if (link.title == "Rent") {
                    this.rootStore.searchStore.setMode(ModeEnums.rent, true)
                }

                if (link.title == "Sold") {
                    this.rootStore.searchStore.setMode(ModeEnums.sold, true)
                }
            }
            
            if (link?.path !== '#' && ROUTER_PUSH) {
                this.rootStore.history.push(link!.path)
            }
        }
        
    }

    get tabLinks() {
        return this.links[0].childlinks.filter(x => x.isTab === true);
    }
    

    get activeLink() {
        return this.currentLink;
    }

    get activeChildLink(){
        return this.currentChildLink;
    }

    setOpenMenu = () => {
        this.openMenu = true
    }

    setCloseMenu = () => {
        this.openMenu = false;
    }

    get openMenuState() {
        return this.openMenu;
    }

}