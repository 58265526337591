
export interface Metadata {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
}

export class PaginatedResults<T> {
    items: T;
    pagination: Metadata;

    constructor(items: T, pagination: Metadata) {
        this.items = items;
        this.pagination = pagination;
    }
}

export class PagingParams {
    pageNumber = 1;
    pageSize = 2;
}



export interface SearchParams {
    pageNumber: number;
    pageSize: number;
    mode: string;
    suburbs: string[];
    pTypes: string[];
    features: string[];
    price: string[];
    bedRooms: string[];
    bathRooms: string[];
    parking: string[];
    orderBy?: string;
    searchTerm?: string;
}

export enum SearchParamsEnums {
    pageNumber = 'pageNumber',
    pageSize = 'pageSize',
    mode = 'mode',
    suburbs = 'suburbs',
    pTypes = 'pTypes',
    features = 'features',
    price = 'price',
    bedRooms = 'bedRooms',
    bathRooms = 'bathRooms',
    parking = 'parking',
    orderBy = 'orderBy',
    searchTerm = 'searchTerm'
}
