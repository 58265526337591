import { BellSlashIcon } from '@heroicons/react/24/outline'

type Props = {
    title: string;
    label?: string;
    itemCount: string;
}

const RecentSearchButton = ({title, label, itemCount}: Props) => {
  return (
    <div className='flex flex-col w-full rounded-sm border lg:w-[194px]
     border-dom-grey-light px-3 py-1 relative'>
        <div className='text-[12px] w-6 h-6 font-semibold flex justify-center items-center
        rounded-full absolute right-3 top-[-11px] text-white bg-rose-500'>
            <div>
                {itemCount}
            </div>
        </div>
        <div className='text-left text-dom-green-default text-[14px] font-semibold'>{title}</div>  
        <div className='flex flex-row justify-between items-center'>
            
            {label ? (
                <div className='text-dom-grey-default text-[14px] font-semibold'>{label}</div>
            ) : (
                <div>&nbsp;</div>
            )}
            <div>
                 <BellSlashIcon className="h-4 w-4 text-dom-green-default" />
            </div>
        </div>

    </div>
  )
}

export default RecentSearchButton