
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline'
import useScreenSize from 'common/hooks/useScreenSize';
import { divide } from 'lodash';

type SuburbSearchButtonProps = {
  isDisabled: boolean,
  handleClick: () => void,
  greyedOut?: boolean,
  smallVersion?: boolean
}

const SearchButton = ({ isDisabled, handleClick, greyedOut, smallVersion }: SuburbSearchButtonProps) => {

  const {mediumScreen} = useScreenSize();

  return (
    <>
      {smallVersion ? (
        <div className='h-[50px]'>
          <button onClick={handleClick} disabled={isDisabled} className={`font-sans h-full font-bold text-xl rounded-r-lg border-none
        text-white p-4 items-center justify-center text-center ${isDisabled ? "bg-dom-grey-light" : "btn-green-base cursor-pointer"}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
              <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM2 9a7 7 0 1 1 12.452 4.391l3.328 3.329a.75.75 0 1 1-1.06 1.06l-3.329-3.328A7 7 0 0 1 2 9Z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      ) : (
        <div className='h-[87px]'>
          <button onClick={handleClick} disabled={isDisabled}
            className={`flex flex-row gap-2 font-sans font-bold text-xl rounded-r-[3px] border-none w-full h-full
          text-white p-4 items-center justify-center text-center ${greyedOut ? "bg-dom-grey-light" : "btn-green-base cursor-pointer"}`}>
            {mediumScreen ? <MagnifyingGlassCircleIcon className="h-6 w-6" /> : <div className='px-3'><MagnifyingGlassCircleIcon className="h-8 w-8" /></div>}
            {mediumScreen && <div>Search</div>}
          </button>
        </div>
      )}

    </>

  )
}

export default SearchButton