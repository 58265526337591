import { DateTime } from 'luxon';
import { makeAutoObservable } from 'mobx';
import { IRootStore } from 'stores';
import { ListingDto, ListingTypes, ListingTypesLabels, PropertyDto, UserActions } from '../../dtos';


export default class ListingModel implements Omit<ListingDto, "dateListingStarts" | "dateListingExpires"> {

    dto: ListingDto;
    rootStore: IRootStore;
    id: string;

    title: string;

    listingDescription: string;

    listingRef: string;

    propertyId: string;

    property: PropertyDto | undefined;

    price: number;

    listingType: ListingTypes;

    contactEmail: string;
    contactNumber: string;

    showNumber: boolean;

    dateListingStarts: DateTime;

    dateListingExpires: DateTime;

    userAction: UserActions;

    clientSecret?: string | undefined;

    paymentIntentId?: string | undefined;

    constructor(dto: ListingDto, rootStore: IRootStore) {
        makeAutoObservable(this);
        this.dto = dto;
        this.rootStore = rootStore;

        this.id = dto.id ?? '';
        this.title = dto.title ?? '';
        this.listingDescription = dto.listingDescription ?? '';
        this.listingRef = dto.listingRef ?? '';
        this.propertyId = dto.propertyId ?? '';
        this.price = dto.price ?? 0;
        this.listingType = dto.listingType;
        this.contactEmail = dto.contactEmail ?? '';
        this.contactNumber = dto.contactNumber ?? '';
        this.showNumber = dto.showNumber ?? false;
        this.dateListingStarts = DateTime.fromISO(dto.dateListingStarts);
        this.dateListingExpires = DateTime.fromISO(dto.dateListingExpires);
        this.userAction = dto.userAction;
        this.clientSecret = dto.clientSecret ?? '';
        this.paymentIntentId = dto.paymentIntentId ?? '';


        this.property = dto.property ?? undefined;
        // if (dto.property) {
        //     this.property = this.addProperty(dto.property) ?? undefined;
        // }

    }

    // private addProperty = (property: PropertyDto) => {
    //     return this.rootStore.propertyStore.setProperty(property);
    // };

    get listingTypeLabel(){
        return ListingTypesLabels[this.listingType]
    }
}
