import { AxiosInstance } from "axios";
import { requests } from "stores/storeUtils";
import { Account } from "../Account.model";
import { account } from "stubs/accounts";
import { IAccountAgent } from "../AccountAgent";

export class MockAccountAgent implements IAccountAgent {
    constructor(private api: AxiosInstance) {
    }

    requests = requests(this.api);

    login(values: any): Promise<Account> {
        return Promise.resolve(account);
    }

    register(values: any): Promise<Account> {
        return this.requests.post<Account>('account/register', values)
    }

    currentAccount(): Promise<Account> {
        return this.requests.get<Account>('account/me')
    }
}
