import { SearchListing } from "area/search/models";
import { useStore } from "stores";
import ListingCardImageSlider from "./ListingCardImageSlider";
import { useState } from "react";

interface Props {
  listing: SearchListing
}

export default function ListingCard({ listing }: Props) {

  const [images, setImages] = useState<string[]>([])

  const { searchStore: {
    setSelectedListing,
    getExtraImages
  } } = useStore();


  const getImages = async () => {
    const extraImages = await getExtraImages(listing.id);
    setImages(extraImages);
  }

  return (
    <div className="rounded-[4px] sm:w-[580px] sm:h-[455px] hover:shadow-xl
    border border-dom-grey-light cursor-pointer flex flex-col"
      onClick={() => setSelectedListing(listing)}>

        <div id="listing-image" className="grow">
          {/* <img src={listing.mainImage} alt="listing-card" /> */}
          <ListingCardImageSlider mainImage={listing.mainImage} getImages={getImages} images={images}  />
        </div>

        <div id="listing-content" className="flex flex-col gap-2 p-5">

          <h1>{listing.title}</h1>

          <h3>{listing.address}</h3>

          <p>
            {listing.bedrooms} Bedrooms {listing.bathrooms} Bathrooms {listing.landSize} m2
          </p>
        </div>

    </div>
  );
}
