import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import UserModel from "./models/UserModel";
import { FieldValues } from "react-hook-form";
import { UserDto, UserUpdateMapper } from "./dtos";
import { IUserAgent } from "./UserAgent";
import { IRootStore } from "stores";

export default class UserStore {
    
    rootStore: IRootStore;
    user: UserModel | null = null;
    loadingInitial: boolean = false;



    constructor(private agent: IUserAgent, rootStore: IRootStore) {
        makeAutoObservable(this);

        this.rootStore = rootStore;
    }

    get isUserLoaded() {
        return !!this.user;
    }

    getCurrentUser = async () => {
        try {

            const dto = await this.agent.currentUser();

            runInAction(() => {
                if (dto) {
                    this.user = new UserModel(dto, this.rootStore);
                    // if (this.user?.isUserSetupComplete === false) {
                    //     history.push('/user/complete-profile');
                    // }
                }
            })
            return this.user;
        } catch (error) {
            toast.error('No UserProfile could be found');
        } 
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    updateUser = async (data: FieldValues) => {
        this.setLoadingInitial(true);
        try {
            const payload = UserUpdateMapper(data);
            let dto = await this.agent.update(payload);


            runInAction(() => {
                this.user = new UserModel(dto, this.rootStore);
            })

        } catch (error) {
            // console.log(error);
        } finally {
            this.setLoadingInitial(false);
        }
    }
}