import { makeAutoObservable, runInAction } from 'mobx';
import { PaymentIntentDto } from './dtos';
import { IRootStore } from 'stores';
import { IPaymentAgent } from './PaymentAgent';

export default class PaymentStore {
    rootStore: IRootStore;

    paymentIntent: PaymentIntentDto | undefined;

    constructor(private agent: IPaymentAgent, rootStore: IRootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    getPaymentIntent = async (listingId: string) => {
        try {
          const intent = await this.agent.getPaymentIntent(listingId);
          runInAction(() => {
            this.paymentIntent = intent;
          });
        } catch (error) {
          throw error;
        }
      }


}
