import { Typography } from "@mui/material";

export default function ProtectedPage() {
    return (
        <>
            <Typography variant='h2'>
                Protected Page
            </Typography>
        </>
    )
}