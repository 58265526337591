import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import ClearButton from './ClearButton';
import { ModeEnums } from 'area/search/models';
import { useStore } from 'stores';

type ModeControlProps = {
  header: string;
  target: string;
}

const ModeControl = ({header, target}: ModeControlProps) => {

  const { searchHelper: {
    setMode, filterValues
    }
 } = useStore();

  const [selectedMode, setSelectedMode] = useState<string>('')

  const handleChange = (mode: string) => {
    setSelectedMode(mode);
    setMode(mode);
  }


  function handleClear() {
  }

  useEffect(() => {
    const mode = filterValues.mode
    setSelectedMode(mode);
  }, [filterValues.mode])


  type ModeButtonProps = {
    title: string,
    mode: ModeEnums,
    roundedLeft?: boolean,
    roundedRight?: boolean
  }
  const ModeButton = (props: ModeButtonProps) => {

    return <button 
      onClick={() => handleChange(props.mode)} 
      className={`px-8 py-2 font-semibold border ${props.roundedLeft ? "rounded-l-sm" : ""} ${props.roundedRight ? "rounded-r-sm" : ""} hover:text-dom-green-default hover:border hover:border-dom-green-default hover:bg-gray-50
     border-dom-grey-light ${selectedMode == props.mode ? "border border-dom-green-default bg-dom-green-ultralight text-dom-green-default" : "text-dom-grey-dark "}`}>
      {props.title}
    </button>
  }

  return (
    <div className='flex flex-col gap-2 p-4'>
      <div className='flex justify-between'>
        <h2 className='title-16-grey'>{header}</h2>
        {/* <ClearButton handleClear={handleClear} /> */}
      </div>
        <div className='flex flex-row mx-auto'>
          {ModeButton({
            title: 'Buy',
            mode: ModeEnums.buy,
            roundedLeft: true
          })}
          {ModeButton({
            title: 'Rent',
            mode: ModeEnums.rent
          })}
          {ModeButton({
            title: 'Sold',
            mode: ModeEnums.sold,
            roundedRight: true
          })}
        </div>
    </div>
  )
}

export default observer(ModeControl)