import React from 'react'

type Props = {
    handleClear: () => void
}

const ClearButton = ({handleClear}: Props) => {
  return (
    <div onClick={handleClear} className='text-dom-green-default cursor-pointer hover:underline underline-offset-4'>Clear</div>
  )
}

export default ClearButton