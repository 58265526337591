
import { useStore } from 'stores';
import TabButton from './TabButton'

const TabButtons = () => {

  const { routerStore: { tabLinks } } = useStore();

  const tabs = tabLinks.map(x => (
    <TabButton key={x.id} isActive={false} link={x} />
  ));
  
  return (
    <div className='flex flex-row gap-1 items-center overflow-clip'>
          {tabs}
    </div>
  )
}

export default TabButtons