import { createTheme, Theme } from "@mui/material";
import { CSSProperties } from 'react';

const darkMode: boolean = false;
const paletteType = darkMode ? "dark" : "light";


const arcOrange = "#FFBA60";

export enum FontWeight {
  /** Equivalent of FontWeight 400 */
  regular = 400,
  /** Equivalent of FontWeight 500 */
  medium = 500,
  heavy = 600
}

const primaryMain = '#39AFF8';
const primaryDark = '#0B5489';
const primaryLight = '#94D8ED';


export const theme: Theme = createTheme({
  spacing: 8,
  palette:  {
    // primary: {
    //   main: primaryMain,
    //   dark: primaryDark,
    //   light: primaryLight,
    // },
    // secondary: {
    //   main: arcOrange,
    // },
    neutralShade: {
      main: '#465464'
    },
    mode: paletteType,
    background: {
      default: paletteType === "light" ? "#eaeaea" : "#121212",
    },
  },
  typography: {
    fontSize: 12,
    headline: {
      fontSize: "24px",
      fontWeight: FontWeight.regular,
      lineHeight: "24px"
    },
    title1: {
      fontSize: "16px",
      fontWeight: FontWeight.heavy,
      color: "rgba(0, 0, 0, 0.87)"
    },
    title2: {
      fontSize: "20px",
      fontWeight: FontWeight.regular,
    },
    body1: {
      fontSize: "14px",
      fontWeight: FontWeight.regular,
      color: "rgba(0, 0, 0, 0.6)"
    },
    body2: {
      fontSize: "14px",
      fontWeight: FontWeight.regular,
      lineHeight: "20px"
    },
    body3: {
      fontSize: "16px",
      fontWeight: FontWeight.regular,
      color: 'rgba(0, 0, 0, 0.6)'
    },
    button: {
      fontSize: "14px",
      fontWeight: FontWeight.medium,
      textTransform: "uppercase",
    },
    h6: {
      fontSize: 20,
      fontWeight: FontWeight.medium,
      lineHeight: "32px",
      color: "rgba(0, 0, 0, 0.6)"
    }
  },
});

// Augment the declared theme with the added typography variants
declare module '@mui/material/styles' {

  interface Palette {
    neutral: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }

  interface PaletteColor {
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
  }

  interface Palette {
    neutralShade: {main: string};
  }

  interface PaletteOptions {
    neutralShade?: {main: string};
  }

  interface CommonColors {
    red: string
  }

  interface TypographyVariants {
      headline: CSSProperties;
      title1: CSSProperties;
      title2: CSSProperties;
      body3: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
      headline?: CSSProperties;
      title1?: CSSProperties;
      title2?: CSSProperties;
      body3?: CSSProperties;
  }
  
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    title1: true;
  }
}