import React from 'react'

const OtherLinksBar = () => {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            marginTop: 0,
        }}>
            <div id='top-links' className='bg-dom-cloud-white flex justify-center md:px-5 h-6 items-center w-full'>
                <div className='w-[1280px] flex items-center'>
                    <div id="domain-media-box" className='border-solid border border-dom-grey-default flex h-5'>
                        <div className='inline-flex border-solid border border-dom-grey-default'>
                            <div className='text-[10px] text-dom-grey-default px-1 font-sans font-semibold'>Domain</div>
                            <div className='text-[10px] px-1 font-sans text-white bg-dom-grey-default'>media</div>
                        </div>
                    </div>
                    <div id='seperator' className='border-r border-dom-grey-hover px-1'></div>
                    <div className='flex text-[11px] text-dom-grey-default'>
                        <a className='hover:text-dom-grey-hover cursor-pointer visited:text-dom-grey-default no-underline' href="#">Allhomes</a>
                        <div className='border-dom-grey-hover px-1'>|</div>
                        <a className='hover:text-dom-grey-hover cursor-pointer visited:text-dom-grey-default no-underline' href="#">Commercial Real Estate</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OtherLinksBar