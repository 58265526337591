import { useCallback, useEffect, useRef, useState } from 'react'
import { debounce } from "lodash"
import { ClickAwayListener } from '@mui/material';
import { Postcode } from 'area/search/models';
import { observer } from 'mobx-react-lite';
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { MapPinIcon } from '@heroicons/react/24/outline'
import { HomeModernIcon } from '@heroicons/react/24/outline'
import { useStore } from 'stores';


// const options: SuburbOption[] = [
//   { label: "Runcorn, QLD, 4113", value: "runcorn-qld-4113", id: 1 },
//   { label: "Calamvale, QLD, 4116", value: "calamvale-qld-4116", id: 2 },
//   { label: "Eight Mile Plains, QLD, 4113", value: "eight-mile-plains-qld-4113", id: 3 },
//   { label: "Sunnybank Hills, QLD, 4109", value: "sunnybank-hills-qld-4109", id: 4 },
//   { label: "Rochedale South, QLD, 4123", value: "rochedale-south-qld-4123", id: 5 },
//   { label: "Sunnybank, QLD, 4109", value: "sunnybank-qld-4109", id: 6 },
//   { label: "Mount Gravatt East, QLD, 4122", value: "mount-gravatt-east-qld-4122", id: 7 }
// ]

type Props = {
  showFilterButton: boolean;
  smallVersion?: boolean;
}

const SuburbAutocomplete = ({ showFilterButton, smallVersion }: Props) => {

  const inputRef = useRef<HTMLInputElement>(null)
  const [textVal, setTextVal] = useState('');

  const [focusState, setFocusState] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const {
    searchStore: {
      setToggleSearchPage,
      searchPostcodes,
      selectedPostcodes,
      availablePostcodes,
      addPostcode,
      removePostcode
    },
  } = useStore();

  const onSearch = (val: string) => {
    if (val) {
      searchPostcodes(val);
    }
  }

  function clearOptions() {
    // setValues([] as Postcode[])
  }

  function selectOption(option: Postcode) {
    if (selectedPostcodes.includes(option)) {
      removePostcode(option)
    } else {
      addPostcode(option)
    }
    setTextVal('')
  }


  useEffect(() => {
    if (isOpen) {
    } else {
      inputRef.current?.focus()
    }

  }, [isOpen, inputRef])

  useEffect(() => {
    inputRef.current?.focus()
  }, [focusState])

  const debounceFn = useCallback(debounce(handleDebounceFn, 300), []);

  function handleDebounceFn(inputValue: string) {
    onSearch(inputValue)

    if (inputValue) {
      if (inputValue.length < 1) {
        setIsOpen(false)
        return;
      }
      if (!isOpen) {
        setIsOpen(true)
      }
    } else {
      setIsOpen(false)
    }
  }

  const filterButton = () => {
    return (
      <div>
        <div onClick={e => {
          e.stopPropagation()
          setToggleSearchPage()
        }} className='rounded-md border border-solid border-gray-500 px-2 py-2 cursor-pointer flex flex-row w-20 items-center gap-1'>
          <svg className='text-[6px]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.858 6C12.411 4.27954 10.8587 3 9 3C7.1413 3 5.58905 4.27954 5.14197 6H2V8H5.14197C5.58905 9.72046 7.1413 11 9 11C10.8587 11 12.411 9.72046 12.858 8H22V6H12.858ZM9 9C7.89746 9 7 8.10254 7 7C7 5.89746 7.89746 5 9 5C10.1025 5 11 5.89746 11 7C11 8.10254 10.1025 9 9 9ZM15 13C13.1413 13 11.589 14.2795 11.142 16H2V18H11.142C11.589 19.7205 13.1413 21 15 21C16.8587 21 18.4109 19.7205 18.858 18H22V16H18.858C18.4109 14.2795 16.8587 13 15 13ZM15 19C13.8975 19 13 18.1025 13 17C13 15.8975 13.8975 15 15 15C16.1025 15 17 15.8975 17 17C17 18.1025 16.1025 19 15 19Z" fill='currentColor' ></path></svg>
          <div className='text-dom-grey-hover'>Filters</div>
        </div>
      </div>
    )
  }

  const searchOptions = <div className={`relative m-0 p-0 list-none cursor-pointer w-full left-0 bg-white z-50 ${isOpen ? "flex flex-col items-start visible" : "hidden"}`}>
    {availablePostcodes.length > 0 && (
      <div className='flex w-full ml-2 mb-3 items-center gap-3'>
        <MapPinIcon className="h-6 w-6 text-dom-grey-hover" />
        <div className='text-dom-grey-hover text-sm'>LOCATIONS</div>
      </div>
    )}
    {availablePostcodes.map((option, index) => (
      <div onClick={e => {
        e.stopPropagation()
        selectOption(option)
        setIsOpen(true)
      }}
        key={option.id}
        className={`flex justify-between px-4 py-2 w-full hover:bg-dom-cloud-white font-sans font-semibold text-dom-grey-hover`}>
        <div>{option.label}</div>
        <PlusCircleIcon className="h-6 w-6 text-dom-grey-hover" />
      </div>
    ))}
  </div>

  const suggestedOptions = <div className={`relative m-0 p-3 list-none cursor-pointer w-full left-0 bg-white z-50 ${isOpen ? "flex flex-col items-start visible" : "hidden"}`}>
    {availablePostcodes.length > 0 && (
      <div className='flex w-full ml-2 mb-3 items-center gap-3'>
        <HomeModernIcon className="h-6 w-6 text-dom-grey-hover" />
        <div className='text-dom-grey-hover text-sm'>SUGGESTED FOR YOU</div>
      </div>
    )}
    <div className='flex flex-row flex-wrap px-1'>
    {availablePostcodes.map((option, index) => (
      
        <div onClick={e => {
          e.stopPropagation()
          selectOption(option)
          setIsOpen(true)
        }}
          key={option.id}
          className={`flex text-sm items-center border m-1 border-dom-grey-light rounded-full shrink-0 gap-1
          flex-wrap px-2 py-2 hover:bg-dom-cloud-white font-sans font-semibold text-dom-grey-hover`}>
            <PlusCircleIcon className="h-5 w-5 text-dom-grey-hover" />
          <div>{option.label}</div>
        </div>
      
    ))}
    </div>
  </div>



  return (
    <ClickAwayListener onClickAway={_ => {
      setIsOpen(false)
      setFocusState(false)
      inputRef.current?.blur()
    }}>

      <div onClick={_ => {
        setFocusState(true)
        setIsOpen(true)
        inputRef.current?.focus()
      }} tabIndex={0} className={`absolute w-full
          border-2 ${smallVersion ? "min-h-[50px]" : "min-h-[87px]"}
          ${focusState ? "border-dom-green-default" : "border-dom-grey-light"}
          rounded outline-none bg-white z-50`}>

        <div id='filter-input' className={`flex flex-row justify-between w-full min-h-[30px] ${smallVersion ? "mt-2 px-1" : "mt-5 px-5"}`}>

          <div className={`flex flex-row overflow-clip flex-wrap items-center pr-3`}>

            <div id='buttons' className={`flex ${focusState ? "flex-wrap" : "flex-row"} text-left gap-3 grow overflow-clip min-w-0 min-h-0`}>
              {selectedPostcodes.map(v => (
                // buttons
                <div
                  className={`flex flex-row shrink-0 items-center rounded-sm
                   bg-dom-grey-light p-1 text-[14px] font-normal
                   text-dom-grey-dark cursor-pointer outline-none
                  hover:bg-dom-grey-hover hover:text-dom-cloud-white`}
                  key={v.id}>
                  <div className='' onClick={e => {
                    setFocusState(true)
                    setIsOpen(true)
                  }}>
                    {v.label}
                  </div>
                  <div onClick={e => {
                    e.stopPropagation()
                    selectOption(v)
                    setFocusState(true)
                  }} className="px-2 ml-1 hover:bg-dom-grey-dark">&times;</div>
                </div>
              ))}

              {focusState ? (
                <div className='pl-1 min-w-0 min-h-0'>
                  <input ref={inputRef} className='border-none outline-none' value={textVal} onChange={e => {
                    setTextVal(e.target.value)
                    debounceFn(e.target.value);
                  }} type="text" style={{ width: `${textVal.length + .2}ch` }} />
                  <span className={`${selectedPostcodes.length || textVal.length > 0 ? "hidden" : "text-dom-grey-default overflow-clip whitespace-nowrap"}`}>Try a location or a school or project name</span>
                </div>
              ) : (
                <span className={`${selectedPostcodes.length || textVal.length > 0 ? "hidden" : "text-dom-grey-default overflow-clip whitespace-nowrap"}`}>Try a location or a school or project name</span>
              )}
            </div>

          </div>

          {showFilterButton && filterButton()}

        </div>

        {isOpen && (
          <div id='filter-results' className={`${isOpen ? "flex flex-col items-start" : ""} w-full mt-2`}>
            {textVal && isOpen && (
              <></>
              // TODO: Come back to this. Not sure if needed but keeping just for now in case I wish to implement it later
              // <div className='flex flex-row items-center justify-between w-full'>
              //   <div className='flex flex-row gap-1'>
              //     <div>
              //       Keywords <span>{textVal}</span>
              //     </div>
              //   </div>
              //   <div>add button</div>
              // </div>
            )}
            {selectedPostcodes.length === 0 ? (
              searchOptions
            ) : (
              suggestedOptions
            )}
          </div>
        )}

      </div>

    </ClickAwayListener>
  )
}

export default observer(SuburbAutocomplete)