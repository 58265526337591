import { ClickAwayListener } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect, useRef, useState } from 'react'
import useScreenSize from 'common/hooks/useScreenSize';
import { useStore } from 'stores';


type Props = {
    children: ReactNode,
    target: string;
    toggleOpen: (label: string) => void;
    opened: string | undefined;
    label: string,
    defaultStatus: boolean,
    icon: ReactNode,
    iconLeft?: boolean,
    noPadding?: boolean
}

const PANEL_WIDTH: number = 380;
const HALF_WIDTH = PANEL_WIDTH / 2;

const FilterButtonBase = ({ children, target, toggleOpen, opened, label, defaultStatus, icon, iconLeft, noPadding }: Props) => {

    const { searchHelper: {
        applyFilterToStore
    }
    } = useStore();

    const { screenSize } = useScreenSize();

    const buttonRef = useRef<HTMLButtonElement>();
    const [position, setPosition] = useState<number>(0)

    function handleClickAway() {
        toggleOpen('')
    }

    function handleClicked() {
        if (opened === target) {
            toggleOpen('')
        } else {
            toggleOpen(target)
        }
    }

    function handleCancel() {
    }

    function processPosition() {
        let buttonLeft = buttonRef.current.offsetLeft;
        let buttonWidth = buttonRef.current.offsetWidth;
        let buttonCenter = buttonLeft + (buttonWidth / 2);
        let spaceLeft = buttonCenter;
        let spaceRight = (screenSize.width - buttonCenter);
        let isEnoughLeft: boolean = buttonLeft > (HALF_WIDTH);
        let isEnoughRight: boolean = spaceRight > (HALF_WIDTH);

        let panelCenter = buttonCenter - (HALF_WIDTH);

        if (!isEnoughLeft) {
            panelCenter = buttonCenter - spaceLeft + 0;
            setPosition(panelCenter);
            return;
        }

        if (!isEnoughRight) {
            panelCenter = buttonCenter - (PANEL_WIDTH - spaceRight) - 10;
            setPosition(panelCenter);
            return;
        }

        setPosition(panelCenter);
        return;
    }

    useEffect(() => {
        processPosition()
    }, [screenSize]);

    return (
        <div>
            <button ref={buttonRef} onClick={(e) => {
                e.stopPropagation()
                handleClicked()
            }} className={`flex ${iconLeft ? "flex-row-reverse" : ""} items-end gap-1 border text-[14px]
             text-dom-grey-dark font-sans font-semibold border-dom-grey-light p-2 
             rounded-full ${defaultStatus ? "border-dom-green-default bg-dom-green-ultralight text-dom-green-default" : ""}
             hover:bg-gray-100
             `}>
                <div className='whitespace-nowrap'>{label}</div>
                <div>{icon}</div>
            </button>
            {(opened === target) && (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div style={{
                        position: 'absolute',
                        left: position,
                        top: buttonRef.current.offsetTop + 50
                    }} className='drop-shadow-[0_5px_5px_rgba(0,0,0,0.3)] z-20 bg-white rounded-md'
                    >
                        <div style={{
                            width: `${PANEL_WIDTH}px`,
                            maxHeight: '55vh',
                            overflowY: 'auto',
                        }} className={`${noPadding ? "" : "px-3 py-1"}`}>
                            {children}
                        </div>
                        <div className='flex flex-row justify-end gap-2 p-5'>
                            <button className='border border-dom-grey-hover px-4 py-2 rounded font-bold hover:bg-gray-100' onClick={handleCancel}>Cancel</button>
                            <button className='border border-dom-green-default text-dom-green-default px-4 py-2 rounded font-bold hover:bg-gray-100' onClick={() => {
                                toggleOpen('')
                                applyFilterToStore()
                            }}>Apply</button>
                        </div>
                    </div>
                </ClickAwayListener>
            )}
        </div>
    )
}

export default observer(FilterButtonBase)