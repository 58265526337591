import { SwipeableDrawer, IconButton } from '@mui/material';
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { MenuLink } from '../stores/RouterStore';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ChevronDownIcon } from '@heroicons/react/24/outline'

import styles from "./MainNavBar.module.css";
import useScreenSize from 'common/hooks/useScreenSize';
import { useStore } from 'stores';

type MainNavBarProps = {
    lightMode: boolean;
}

const MainNavBar = ({ lightMode }: MainNavBarProps) => {

    const [openDrawer, setOpenDrawer] = useState<boolean>(false);

    const { largeScreen } = useScreenSize();

    const drawerWidth = 350;

    const iOS =
        typeof navigator !== "undefined" &&
        /iPad|iPhone|iPod/.test(navigator.userAgent);

    const dropdownRef = useRef(null);

    const { 
        routerStore: { links, openMenuState, setHomeTab },
        accountStore: { isLoggedIn, account },
        searchStore: {init}
     } = useStore();


    const submenu = (x: MenuLink) => {

        const isMega = x.hasMegaMenu;

        const childLinks = x.childlinks.map((child) => (
            <div key={child.id}>
                <div onClick={child.setActiveChild} className={`cursor-pointer ${child.activeState ? "text-dom-green-default" : ""} ${child.isTabOnly ? "hidden" : ""}`}>{child.title}</div>
            </div>
        ));

        const megaMenu = x.hasMegaMenu && (
            x.megaMenus.map((mega, index) => (
                <div key={index} className='flex flex-col w-[440px]'>
                    <div className='text-lg'>{mega.header}</div>
                    <div className='flex flex-col flex-wrap'>
                        {mega.childlinks.map((child) => (
                            <div key={child.id} onClick={child.setActiveChild}
                                className={`w-1/2 cursor-pointer ${child.activeState ? "text-dom-green-default" : ""} ${child.isTabOnly ? "hidden" : ""}`}>
                                {child.title}
                            </div>
                        ))}
                    </div>

                </div>
            ))
        )

        return (
            <ClickAwayListener onClickAway={x.setInactive}>
                <div style={{
                    position: 'absolute',
                    minWidth: '210px',
                    left: '50%',
                    transform: 'translate(-50%, 18px)'
                }} className='bg-white p-4 text-black flex flex-col'>

                    {isMega ? megaMenu : childLinks}
                </div>
            </ClickAwayListener>
        )
    }

    const underlineStyle = lightMode ? styles.white : styles.green;
    const activeStyle = lightMode ? styles.whiteactive : styles.greenactive;
    const downIcon = <ChevronDownIcon className='h-4 w-4 font-semibold' />

    const menu = links.map((x: MenuLink, index) => {

        return (
            <div key={index} style={{
                zIndex: 100,
                position: 'relative'
            }}>
                <div ref={dropdownRef}
                    className={`font-sans cursor-pointer font-normal ${x.activeState ? activeStyle : ""} ${lightMode ? "text-white" : "text-dom-grey-dark"} ${underlineStyle}`}
                    onClick={(e) => {
                        x.setActive();
                        x.routerStore.setOpenMenu();
                    }}>
                    <div className='flex gap-[2px] items-center'>
                        {x.title}
                        {x.hasChildren && downIcon}
                    </div>
                </div>
                {x.activeState && x.hasChildren && openMenuState && submenu(x)}
            </div>
        )
    });

    const drawerSubmenu = (x: MenuLink) => {
        return (
            <div className='bg-white p-4 text-lg flex flex-col text-[#515b6e] gap-5'>
                {x.childlinks.map((child) => (
                    <div key={child.id} onClick={() => {
                        child.setActiveChild();
                        setOpenDrawer(false);
                    }} className={`cursor-pointer hover:text-green-700 ${child.activeState ? "text-green-700" : ""}`}>{child.title}</div>
                ))}
            </div>
        )
    }

    const drawerMenu = links.map((x: MenuLink, index) => {

        return (
            <div key={index} className='flex-col'>
                <div className={`flex justify-between text-lg cursor-pointer hover:text-green-700 ${x.activeState ? "text-green-700" : ""}`} onClick={(e) => {
                    if (!x.activeState) {
                        x.setActive();

                    } else {
                        x.setInactive();
                    }
                }}>
                    <div>{x.title}</div>
                    {x.hasChildren && (
                        <div>
                            {x.activeState && <KeyboardArrowDownIcon fontSize='medium' />}
                            {!x.activeState && <KeyboardArrowUpIcon fontSize='medium' />}
                        </div>
                    )}
                </div>
                {x.activeState && x.hasChildren && drawerSubmenu(x)}
            </div>
        )
    });



    const drawer = (
        <>
            <SwipeableDrawer
                anchor='left'
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onOpen={() => setOpenDrawer(true)}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <div className='px-3 py-3'>
                    <IconButton
                        onClick={() => setOpenDrawer(!openDrawer)}
                        disableRipple
                        sx={{
                            color: "bg-[#515b6e]",
                            alignSelf: "start"
                        }}
                    >
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <div className='flex flex-col gap-5 px-3 py-3'>
                        {drawerMenu}
                    </div>
                </div>

            </SwipeableDrawer>
            <IconButton
                onClick={() => setOpenDrawer(!openDrawer)}
                disableRipple
                sx={{
                    color: lightMode ? "#FFF" : "#3c475b"
                }}
            >
                <MenuIcon />
            </IconButton>
        </>
    );

    return (
        <div className='flex text-white justify-center h-[54px] pr-3 lg:h-[76px] lg:px-6 items-center w-full'>
            <div className='w-[1280px] flex items-center justify-between'>

                <div className='hidden lg:visible lg:block'>
                    <div className='w-[102px] cursor-pointer' onClick={_ => {
                        setHomeTab();
                        init();
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 22"><path fill={`${lightMode ? "#FFF" : "#0EA800"}`} d="M72.51 18.27v-7c0-4.28-2.63-5.51-6-5.51-3.62 0-6 1.89-6 5h2.79c0-1.55 1-2.42 3.29-2.42 2.49 0 3.21 1.08 3.21 2.09 0 .84-.18 1.29-1.65 1.56l-3.32.63c-2.91.54-5 1.86-5 4.91 0 2.82 2.34 4.47 5 4.47A6.15 6.15 0 0070 19.62V20a1.54 1.54 0 001.41 1.66 1.37 1.37 0 00.35 0H74v-2.34h-.36c-.86 0-1.13-.32-1.13-1.05zm-2.66-2.69c0 2.09-1.77 3.89-4.53 3.89-1.64 0-2.54-.84-2.54-2.22s1-2 3.11-2.48l2.67-.57a4.21 4.21 0 001.29-.45zM7 .12H0V21.6h6.71c6.62 0 11-4.53 11-10.76C17.7 3.83 12.67.12 7 .12zm-.29 18.81H2.94V2.82h3.47c4.91 0 8.33 2.66 8.33 8s-3.69 8.11-8.03 8.11zM75.66 6.15h2.68V21.6h-2.68zM87.87 5.75a5.53 5.53 0 00-4.42 2v-1.6h-2.68V21.6h2.68v-9.75a3.73 3.73 0 016-2.95 4 4 0 01.48.44l.07.09a3.68 3.68 0 01.89 2.41v9.76h2.68v-9.53c.02-3.98-2.1-6.32-5.7-6.32zM77 0a1.81 1.81 0 101.82 1.8A1.82 1.82 0 0077 0zM52.18 5.48L47.4 8.73l-4.8-3.25-3.45 2.33V6.15h-2.68V21.6h2.68V11.06l3.45-2.34 3.43 2.34V21.6h2.68V11.06l3.47-2.34 3.46 2.34V21.6h2.68V9.62l-6.14-4.14zM27 5.74a7.93 7.93 0 00-8.08 7.78v.36a7.91 7.91 0 007.7 8.12H27a7.91 7.91 0 008-7.82v-.3a7.93 7.93 0 00-7.72-8.14zm0 13.54a5.41 5.41 0 115.4-5.42 5.42 5.42 0 01-5.4 5.42z"></path></svg>
                    </div>
                </div>

                <div className='lg:hidden'>
                    <div className='flex gap-1 items-center'>
                        {drawer}
                        <div className="w-[80px] cursor-pointer" onClick={_ => {
                        setHomeTab();
                        init();
                    }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 22"><path fill={`${lightMode ? "#FFF" : "#0EA800"}`} d="M72.51 18.27v-7c0-4.28-2.63-5.51-6-5.51-3.62 0-6 1.89-6 5h2.79c0-1.55 1-2.42 3.29-2.42 2.49 0 3.21 1.08 3.21 2.09 0 .84-.18 1.29-1.65 1.56l-3.32.63c-2.91.54-5 1.86-5 4.91 0 2.82 2.34 4.47 5 4.47A6.15 6.15 0 0070 19.62V20a1.54 1.54 0 001.41 1.66 1.37 1.37 0 00.35 0H74v-2.34h-.36c-.86 0-1.13-.32-1.13-1.05zm-2.66-2.69c0 2.09-1.77 3.89-4.53 3.89-1.64 0-2.54-.84-2.54-2.22s1-2 3.11-2.48l2.67-.57a4.21 4.21 0 001.29-.45zM7 .12H0V21.6h6.71c6.62 0 11-4.53 11-10.76C17.7 3.83 12.67.12 7 .12zm-.29 18.81H2.94V2.82h3.47c4.91 0 8.33 2.66 8.33 8s-3.69 8.11-8.03 8.11zM75.66 6.15h2.68V21.6h-2.68zM87.87 5.75a5.53 5.53 0 00-4.42 2v-1.6h-2.68V21.6h2.68v-9.75a3.73 3.73 0 016-2.95 4 4 0 01.48.44l.07.09a3.68 3.68 0 01.89 2.41v9.76h2.68v-9.53c.02-3.98-2.1-6.32-5.7-6.32zM77 0a1.81 1.81 0 101.82 1.8A1.82 1.82 0 0077 0zM52.18 5.48L47.4 8.73l-4.8-3.25-3.45 2.33V6.15h-2.68V21.6h2.68V11.06l3.45-2.34 3.43 2.34V21.6h2.68V11.06l3.47-2.34 3.46 2.34V21.6h2.68V9.62l-6.14-4.14zM27 5.74a7.93 7.93 0 00-8.08 7.78v.36a7.91 7.91 0 007.7 8.12H27a7.91 7.91 0 008-7.82v-.3a7.93 7.93 0 00-7.72-8.14zm0 13.54a5.41 5.41 0 115.4-5.42 5.42 5.42 0 01-5.4 5.42z"></path></svg>
                        </div>
                    </div>
                </div>

                <div className="flex gap-3 items-center text-[14px] lg:text-[16px]">
                    {largeScreen && (
                        <div className="flex gap-4 flex-row h-8 items-center">
                            {menu}
                        </div>
                    )}
                    <div className={`h-[30px] lg:h-[44px] border border-l-0 ${lightMode ? "border-white" : "border-x-dom-grey-light"}`}></div>
                    {isLoggedIn ? (
                        <Link to="/manage">
                            <div className={`relative text-[16px] cursor-pointer ${lightMode ? "text-white" : "text-dom-grey-dark"} ${underlineStyle}`}>
                                <div>{account?.username}</div>
                            </div>
                        </Link>
                    ) : (
                        <Link to="/login">
                            <div className={`relative whitespace-nowrap text-[16px] cursor-pointer ${lightMode ? "text-white" : largeScreen ? "text-dom-grey-dark" : "text-dom-green-default"} ${largeScreen ? styles.green : ""}`}>
                                <div>Log in</div>
                            </div>
                        </Link>
                    )}

                </div>
            </div>
        </div>
    )
}

export default observer(MainNavBar);

