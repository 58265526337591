import { SearchListing } from "area/search/models";
import { PaginatedResults } from "models/pagination";
// import { PaginatedResults } from "models/pagination"



export const searchListings: SearchListing[] = [
    {
      id: "42-condamine-street-runcorn-4113-456DF4A",
      manageListingId: "3f701dec-d46e-4230-aa70-da84df9b98df",
      slug: "42-condamine-street-runcorn-4113",
      title: "Under Contract by Kevin Davis & Roger Brown",
      address: "42 Condamine Street, Runcorn",
      listingRef: "456DF4A",
      propertyId: null,
      dateCreated: null,
      dateListingStarts: null,
      dateListingExpires: null,
      contactEmail: null,
      contactNumber: null,
      price: 290000,
      listingType: "Sale",
      isPremium: false,
      createdById: null,
      suburbSlug: "runcorn-4113",
      propertyType: "House",
      propertyTypeId: 0,
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      features: [
        "Spa Pool",
        "Swimming Pool"
      ],
      bedrooms: 3,
      bathrooms: 1,
      parkingSpaces: 1,
      landSize: 607,
      mainImage: "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988073/oagwpla7j08r6vajs9dn.jpg",
      images: [
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988061/wafqxgyenrgn6du2auwo.jpg",
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1601443269/house2_uia3sj.png"
      ],
      streetNumber: null,
      streetName: null,
      postalCode: null,
      suburbOrCity: null,
      council: null,
      regionOrState: "QLD",
      country: null,
      longitude: null,
      latitude: null,
      googleAddress: null
    },
    {
      id: "28-36-Rushton-Street-runcorn-4113-45AD565",
      manageListingId: "fad6b90a-bcd9-480f-bc02-8aa90c16b854",
      slug: "28-36-Rushton-Street-runcorn-4113",
      title: "Offers",
      address: "28/36 Rushton Street, Runcorn",
      listingRef: "45AD565",
      propertyId: null,
      dateCreated: null,
      dateListingStarts: null,
      dateListingExpires: null,
      contactEmail: null,
      contactNumber: null,
      price: 400000,
      listingType: "Sale",
      isPremium: false,
      createdById: null,
      suburbSlug: "runcorn-4113",
      propertyType: "House",
      propertyTypeId: 0,
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      features: [
        "Air Con",
        "Swimming Pool"
      ],
      bedrooms: 4,
      bathrooms: 5,
      parkingSpaces: 3,
      landSize: 607,
      mainImage: "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988061/wafqxgyenrgn6du2auwo.jpg",
      images: [
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988061/wafqxgyenrgn6du2auwo.jpg",
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1601443269/house2_uia3sj.png"
      ],
      streetNumber: null,
      streetName: null,
      postalCode: null,
      suburbOrCity: null,
      council: null,
      regionOrState: "QLD",
      country: null,
      longitude: null,
      latitude: null,
      googleAddress: null
    },
    {
      id: "145-Warrigal-Road-runcorn-4113-454D565",
      manageListingId: "d9a3eb1b-076b-40a1-bb61-89e8f59bb2a9",
      slug: "145-Warrigal-Road-runcorn-4113",
      title: "$299,000+",
      address: "Lot 1, 145 Warrigal Road, Runcorn, Qld 4113",
      listingRef: "454D565",
      propertyId: null,
      dateCreated: null,
      dateListingStarts: null,
      dateListingExpires: null,
      contactEmail: null,
      contactNumber: null,
      price: 10000000,
      listingType: "Sale",
      isPremium: false,
      createdById: null,
      suburbSlug: "runcorn-4113",
      propertyType: "Unit",
      propertyTypeId: 0,
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      features: [
        "Air Con",
        "Garage"
      ],
      bedrooms: 10,
      bathrooms: 3,
      parkingSpaces: 6,
      landSize: 607,
      mainImage: "https://res.cloudinary.com/dbyluxdsw/image/upload/v1601443269/house2_uia3sj.png",
      images: [
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988061/wafqxgyenrgn6du2auwo.jpg",
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1601443269/house2_uia3sj.png"
      ],
      streetNumber: null,
      streetName: null,
      postalCode: null,
      suburbOrCity: null,
      council: null,
      regionOrState: "QLD",
      country: null,
      longitude: null,
      latitude: null,
      googleAddress: null
    },
    {
      id: "145-Warrigal-Road-runcorn-4113-JDD568",
      manageListingId: "40b2ef59-9a20-48aa-942b-2b2c6d8e8717",
      slug: "145-Warrigal-Road-runcorn-4113",
      title: "$299,000 to $329,000",
      address: "Lot 2, 145 Warrigal Road, Runcorn, Qld 4113",
      listingRef: "JDD568",
      propertyId: null,
      dateCreated: null,
      dateListingStarts: null,
      dateListingExpires: null,
      contactEmail: null,
      contactNumber: null,
      price: 10000000,
      listingType: "Sale",
      isPremium: false,
      createdById: null,
      suburbSlug: "runcorn-4113",
      propertyType: "House",
      propertyTypeId: 0,
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      features: [
        "Air Con",
        "Garage"
      ],
      bedrooms: 10,
      bathrooms: 3,
      parkingSpaces: 6,
      landSize: 607,
      mainImage: "https://res.cloudinary.com/dbyluxdsw/image/upload/v1601443269/house4_g3vm53.png",
      images: [
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988061/wafqxgyenrgn6du2auwo.jpg",
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1601443269/house2_uia3sj.png"
      ],
      streetNumber: null,
      streetName: null,
      postalCode: null,
      suburbOrCity: null,
      council: null,
      regionOrState: "QLD",
      country: null,
      longitude: null,
      latitude: null,
      googleAddress: null
    },
    {
      id: "30-Jandowae-Street-runcorn-4113-ODP4565",
      manageListingId: "4ccbafdd-d544-4276-8806-47674a5e80b8",
      slug: "30-Jandowae-Street-runcorn-4113",
      title: "Under Offer Terence and Michael",
      address: "30 Jandowae Street, Runcorn, Qld 4113",
      listingRef: "ODP4565",
      propertyId: null,
      dateCreated: null,
      dateListingStarts: null,
      dateListingExpires: null,
      contactEmail: null,
      contactNumber: null,
      price: 10000000,
      listingType: "Sale",
      isPremium: false,
      createdById: null,
      suburbSlug: "runcorn-4113",
      propertyType: "House",
      propertyTypeId: 0,
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      features: [
        "Air Con",
        "Garage"
      ],
      bedrooms: 10,
      bathrooms: 3,
      parkingSpaces: 6,
      landSize: 607,
      mainImage: "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988073/oagwpla7j08r6vajs9dn.jpg",
      images: [
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988061/wafqxgyenrgn6du2auwo.jpg",
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1601443269/house2_uia3sj.png"
      ],
      streetNumber: null,
      streetName: null,
      postalCode: null,
      suburbOrCity: null,
      council: null,
      regionOrState: "QLD",
      country: null,
      longitude: null,
      latitude: null,
      googleAddress: null
    },
    {
      id: "28-69-Daw-Rd-runcorn-4113-SDFD54654",
      manageListingId: "74ee880c-eef6-4b41-9626-6ff5c6e6086e",
      slug: "28-69-Daw-Rd-runcorn-4113",
      title: "Auction",
      address: "28/69 Daw Rd, Runcorn, Qld 4113",
      listingRef: "SDFD54654",
      propertyId: null,
      dateCreated: null,
      dateListingStarts: null,
      dateListingExpires: null,
      contactEmail: null,
      contactNumber: null,
      price: 10000000,
      listingType: "Sale",
      isPremium: false,
      createdById: null,
      suburbSlug: "runcorn-4113",
      propertyType: "House",
      propertyTypeId: 0,
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      features: [
        "Air Con",
        "Garage"
      ],
      bedrooms: 10,
      bathrooms: 3,
      parkingSpaces: 6,
      landSize: 607,
      mainImage: "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988073/oagwpla7j08r6vajs9dn.jpg",
      images: [
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988061/wafqxgyenrgn6du2auwo.jpg",
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1601443269/house2_uia3sj.png"
      ],
      streetNumber: null,
      streetName: null,
      postalCode: null,
      suburbOrCity: null,
      council: null,
      regionOrState: "QLD",
      country: null,
      longitude: null,
      latitude: null,
      googleAddress: null
    },
    {
      id: "23-Penarth-Street-runcorn-4113-4564ADFD",
      manageListingId: "1ba8ca64-948f-4196-a566-342e870dec13",
      slug: "23-Penarth-Street-runcorn-4113",
      title: "Offers Over $750,000",
      address: "123 Penarth Street, Runcorn, Qld 4113",
      listingRef: "4564ADFD",
      propertyId: null,
      dateCreated: null,
      dateListingStarts: null,
      dateListingExpires: null,
      contactEmail: null,
      contactNumber: null,
      price: 10000000,
      listingType: "Sale",
      isPremium: false,
      createdById: null,
      suburbSlug: "runcorn-4113",
      propertyType: "House",
      propertyTypeId: 0,
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      features: [
        "Air Con",
        "Garage"
      ],
      bedrooms: 10,
      bathrooms: 3,
      parkingSpaces: 6,
      landSize: 607,
      mainImage: "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988073/oagwpla7j08r6vajs9dn.jpg",
      images: [
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988061/wafqxgyenrgn6du2auwo.jpg",
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1601443269/house2_uia3sj.png"
      ],
      streetNumber: null,
      streetName: null,
      postalCode: null,
      suburbOrCity: null,
      council: null,
      regionOrState: "QLD",
      country: null,
      longitude: null,
      latitude: null,
      googleAddress: null
    },
    {
      id: "33-Pine-Street-runcorn-4113-4545add",
      manageListingId: "393aaa0f-9a0c-4088-90b3-caff79cb830b",
      slug: "33-Pine-Street-runcorn-4113",
      title: "Under Offer Terence and Michael",
      address: "33 Pine Street, Runcorn, Qld 4113",
      listingRef: "4545add",
      propertyId: null,
      dateCreated: null,
      dateListingStarts: null,
      dateListingExpires: null,
      contactEmail: null,
      contactNumber: null,
      price: 10000000,
      listingType: "Sale",
      isPremium: false,
      createdById: null,
      suburbSlug: "runcorn-4113",
      propertyType: "Unit",
      propertyTypeId: 0,
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      features: [
        "Air Con",
        "Garage"
      ],
      bedrooms: 10,
      bathrooms: 3,
      parkingSpaces: 6,
      landSize: 607,
      mainImage: "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988073/oagwpla7j08r6vajs9dn.jpg",
      images: [
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988061/wafqxgyenrgn6du2auwo.jpg",
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1601443269/house2_uia3sj.png"
      ],
      streetNumber: null,
      streetName: null,
      postalCode: null,
      suburbOrCity: null,
      council: null,
      regionOrState: "QLD",
      country: null,
      longitude: null,
      latitude: null,
      googleAddress: null
    },
    {
      id: "1137-Beenleigh-Road-runcorn-4113-SLDDD5",
      manageListingId: "2f225dcd-84d3-409b-aa50-affca80316b0",
      slug: "1137-Beenleigh-Road-runcorn-4113",
      title: "Under Offer Terence and Michael",
      address: "1137 Beenleigh Road, Runcorn, Qld 4113",
      listingRef: "SLDDD5",
      propertyId: null,
      dateCreated: null,
      dateListingStarts: null,
      dateListingExpires: null,
      contactEmail: null,
      contactNumber: null,
      price: 10000000,
      listingType: "Sale",
      isPremium: false,
      createdById: null,
      suburbSlug: "runcorn-4113",
      propertyType: "Unit",
      propertyTypeId: 0,
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      features: [
        "Air Con",
        "Garage"
      ],
      bedrooms: 10,
      bathrooms: 3,
      parkingSpaces: 6,
      landSize: 607,
      mainImage: "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988073/oagwpla7j08r6vajs9dn.jpg",
      images: [
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988061/wafqxgyenrgn6du2auwo.jpg",
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1601443269/house2_uia3sj.png"
      ],
      streetNumber: null,
      streetName: null,
      postalCode: null,
      suburbOrCity: null,
      council: null,
      regionOrState: "QLD",
      country: null,
      longitude: null,
      latitude: null,
      googleAddress: null
    },
    {
      id: "9-Hill-Rd-runcorn-4113-DDAD5465",
      manageListingId: "d279b849-4b12-422b-b660-f4b73d0cafd5",
      slug: "9-Hill-Rd-runcorn-4113",
      title: "U/C BY JOHN MCDONALD",
      address: "9 Hill Rd, Runcorn, Qld 4113",
      listingRef: "DDAD5465",
      propertyId: null,
      dateCreated: null,
      dateListingStarts: null,
      dateListingExpires: null,
      contactEmail: null,
      contactNumber: null,
      price: 10000000,
      listingType: "Sale",
      isPremium: false,
      createdById: null,
      suburbSlug: "runcorn-4113",
      propertyType: "Unit",
      propertyTypeId: 0,
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      features: [
        "Air Con",
        "Garage"
      ],
      bedrooms: 10,
      bathrooms: 3,
      parkingSpaces: 6,
      landSize: 607,
      mainImage: "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988073/oagwpla7j08r6vajs9dn.jpg",
      images: [
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1600988061/wafqxgyenrgn6du2auwo.jpg",
        "https://res.cloudinary.com/dbyluxdsw/image/upload/v1601443269/house2_uia3sj.png"
      ],
      streetNumber: null,
      streetName: null,
      postalCode: null,
      suburbOrCity: null,
      council: null,
      regionOrState: "QLD",
      country: null,
      longitude: null,
      latitude: null,
      googleAddress: null
    }
  ]

  export const paginatedResults: PaginatedResults<SearchListing[]> =  {
    items: searchListings,
    pagination: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 12,
      totalPages: 2
    }
  }