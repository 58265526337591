import { PaginatedResults } from "models/pagination"
import { Postcode, SearchListing, SearchLookups } from "./models"
import { AxiosInstance, AxiosResponse } from "axios";
import { requests } from "stores/storeUtils";

const responseBody = <T> (response: AxiosResponse<T>) => response.data;

export class SearchAgent implements ISearchAgent {
    constructor(private api: AxiosInstance) {}

   requests = requests(this.api);

   find(params: URLSearchParams): Promise<PaginatedResults<SearchListing[]>> {
       return this.api.get<PaginatedResults<SearchListing[]>>('search/listings', {params}).then(responseBody)
   }

   details(id: string): Promise<SearchListing> {
       return this.requests.get<SearchListing>(`search/listings/${id}`)
   }

   extraImages(id: string): Promise<string[]> {
        return this.requests.get<string[]>(`search/listings/${id}/images`)
    }

   filters(): Promise<SearchLookups> {
       return this.requests.get<SearchLookups>('search/lookups')
   }

   searchPostcode(request: string): Promise<Postcode[]> {
       return this.requests.get<Postcode[]>(`search/postcodes?request=${request}`)
   }

   findPostCodes(request: string[]): Promise<Postcode[]> {
    return this.requests.post<Postcode[]>(`search/postcodes`, request)
}

}

export interface ISearchAgent {
    find(params: URLSearchParams): Promise<PaginatedResults<SearchListing[]>>;
    details(id: string): Promise<SearchListing>;
    extraImages(id: string): Promise<string[]>
    filters(): Promise<SearchLookups>;
    searchPostcode(request: string): Promise<Postcode[]>;
    findPostCodes(request: string[]): Promise<Postcode[]>;
}

