
import CloseIcon from '@mui/icons-material/Close';

import styles from "./FullPageSearchFilters.module.css"
import { SuburbSearchBox } from '../components';
import { useEffect, useState } from 'react';
import { PriceControl, PropertyTypesControl, SpacesControl } from '../components/FilterButtons';
import { ModeEnums, SearchValueEnums } from '../models';
import { useStore } from 'stores';
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline';

type FullPageSearchFiltersProps = {
  handleToggle: () => void;
  toggle: boolean;
}

const FullPageSearchFilters = ({ handleToggle, toggle }: FullPageSearchFiltersProps) => {

  const [searchNearby, setSearchNearby] = useState<boolean>(false);

  const {
    searchStore: {
      loaded,
      loadInitialValues,
      priceBuyMarks, priceBuyDefault, priceRentMarks, priceRentDefault
    },
    searchHelper: {
      filterValues, priceBuyValue, priceRentValue, applyFilterToStore
    }
  } = useStore();

  const initialize = async () => {

    if (!loaded) {
      await loadInitialValues();
    }
  }

  useEffect(() => {
    initialize();
  }, [])

  if (!loaded) return <></>

  return (
    <div className={`${styles.searchOverlay} ${toggle ? styles.active : ""} bg-dom-background-white`}>
      <div className='bg-dom-cloud-white'>
        <div className='flex flex-col justify-end max-w-[884px] mx-auto py-6 px-5'>
          <div className='flex justify-end'>
            <button className='justify-self-end title-16-grey-normal items-center' onClick={handleToggle}>Close <CloseIcon fontSize='medium' /></button>
          </div>
          <SuburbSearchBox showFilterButton={false} showTabs={true} greyedOut={false} smallVersion={false} />
          <div className='flex flex-row gap-3 items-center mt-3 ml-1'>
            <input type="checkbox" checked={searchNearby} className='form-checkbox text-dom-green-default' onChange={() => setSearchNearby(prev => !prev)} />
            <p className='title-16-grey-normal'>Search nearby suburbs</p>
          </div>
        </div>
      </div>
      <div className='flex flex-col justify-end max-w-[660px] mx-auto py-6 px-5'>
        <PropertyTypesControl buttonStyle={true} target={SearchValueEnums.pTypes} header='Property Types' />
        <hr />
        {filterValues.mode === ModeEnums.buy && (
          <PriceControl value={priceBuyValue} options={priceBuyMarks} defaultValue={priceBuyDefault} target={SearchValueEnums.price} header='Price' showClearButton={true} />
        )}
        {filterValues.mode === ModeEnums.rent && (
          <PriceControl value={priceRentValue} options={priceRentMarks} defaultValue={priceRentDefault} target={SearchValueEnums.price} header='Price' showClearButton={true} />
        )}
        <hr />
        <SpacesControl spaceValues={filterValues.bedRooms} spaceExactValue={filterValues.bedRoomsExact} target={SearchValueEnums.bedRooms} header='Bedrooms' />
        <hr />
        <SpacesControl spaceValues={filterValues.bathRooms} spaceExactValue={filterValues.bathRoomsExact} target={SearchValueEnums.bathRooms} header='Bathrooms' />
        <hr />
        <SpacesControl spaceValues={filterValues.parking} spaceExactValue={filterValues.parkingExact} target={SearchValueEnums.parking} header='Parking' />
      </div>
      <footer
      className="bg-white
             fixed
             flex
             inset-x-0 
             bottom-0
             mx-auto
             justify-center
             p-4"> 
        <button onClick={() => {
          handleToggle();
          applyFilterToStore();
        }}
            className={`flex flex-row gap-2 font-sans font-bold text-base rounded-r-[3px] border-none w-full h-full max-w-[250px]
          text-white p-2 items-center justify-center text-center btn-green-base cursor-pointer`}>
            <div className='px-1'><MagnifyingGlassCircleIcon className="h-6 w-6" /></div>
            <h1>Submit</h1>
          </button>
    </footer> 
    </div>
  )
}


export default FullPageSearchFilters