import { makeAutoObservable, runInAction } from 'mobx';
import { Metadata } from 'models/pagination';
import { HiLowValue, ModeEnums, Postcode, PropertySizeEnums, SearchValues, SearchListing, SearchValueEnums, LocationParams, MarksList, DefaultsList, FilterValues, Mark, PriceModel, FeatureType, ModeEnumsLabels } from './models';
import { buyDefaults, buyMarks, propertySizeDefaults, propertyTypes, rentDefaults, rentMarks, spaceDefaults, spaceMarks } from './models/defaultValues';
import { priceProcessor, processPriceParams, processSpaceParams, processSuburbsParams, spacesProcessor, checkboxProcessor, stringifyValue, suburbsProcessor, hiLowLabelProcessor } from './searchUtils';
import { ISearchAgent } from './SearchAgent';
import lodash from 'lodash';
import { IRootStore } from 'stores';


const initialMarksList: MarksList = {
  price: buyMarks,
  bathRooms: spaceMarks,
  bedRooms: spaceMarks,
  parking: spaceMarks
}

const parentTypes = propertyTypes.filter(x => x.parent === undefined);


export const initialSearchValues: SearchValues = {
  mode: ModeEnums.buy,
  page: 1,
  pageSize: 10,
  bathRooms: spaceDefaults,
  bathRoomsExact: false,
  bedRooms: spaceDefaults,
  bedRoomsExact: false,
  parking: spaceDefaults,
  parkingExact: false,
  features: [],
  pTypes: [],
  price: buyDefaults,
  propertySize: propertySizeDefaults,
  propertySizeType: PropertySizeEnums.Meters
}

const initialDefaultsList: DefaultsList = {
  price: buyDefaults,
  bathRooms: spaceDefaults,
  bedRooms: spaceDefaults,
  parking: spaceDefaults,
  mode: ModeEnums.buy
}


export default class SearchStore {

  constructor(private agent: ISearchAgent, rootStore: IRootStore) {
    makeAutoObservable(this);

    this.rootStore = rootStore;
    this.init();
  }

  rootStore: IRootStore;
  loaded: boolean = false;

  filtersReady: boolean = false;
  status: string = 'idle';
  searchPageToggleState: boolean = false;
  paramsChanged: boolean = false;
  route: string = '';
  marksList: MarksList = initialMarksList;
  listingRegistry = new Map<string, SearchListing>();
  selectedListing: SearchListing | null = null;
  features: string[] = [];
  propertyTypes: string[] = [];
  listingsLoaded: boolean = false;
  filtersLoaded: boolean = false;
  pagination: Metadata | null = null;
  searchValues: SearchValues;
  postcodesRegistry = new Map<string, Postcode>();
  availablePostcodesMap = new Map<string, Postcode>();
  postcodesLoaded: boolean = false;
  resetFilters: boolean = false;
  defaultsList: DefaultsList = initialDefaultsList;

  priceBuyMarks: Mark[] = [];
  priceBuyDefault: PriceModel = null;
  priceRentMarks: Mark[] = [];
  priceRentDefault: PriceModel = null;
  priceDefaultStatus: boolean = false;

  bedRoomMarks: Mark[] = [];
  bedRoomDefaultStatus: boolean = false;

  bathRoomMarks: Mark[] = [];
  parkingMarks: Mark[] = [];
  propertyTypeMarks: FeatureType[] = [];

  init = () => {
    this.searchValues = initialSearchValues;
  }

  public loadInitialValues = async (params?: LocationParams, trigger?: boolean) => {

    await this.loadPricingTypes();
    await this.loadBedroomValues();
    await this.loadBathroomValues();
    await this.loadParkingValues();
    await this.loadPropertyTypes();

    if (this.loaded == false && params) {
      await this.processParamsFromSearchPage(params);
    }

    await this.rootStore.searchHelper.loadFilterValues();

    runInAction(() => {
      this.loaded = true;
    })
    
    if (trigger) {
      this.triggerSearch();
    }
  }

  private loadPricingTypes = (): Promise<void> => {

    // Load Buy Defaults
    if (this.priceBuyMarks.length == 0) {

      const marks: Mark[] = [];

      buyMarks.forEach((dto, index) => {
        dto.index = index;
        marks.push(dto);
        this.priceBuyMarks.push(dto);
      });
      this.priceBuyDefault = new PriceModel(lodash.first(marks), lodash.last(marks));
    }

    // Load Rent Defaults
    if (this.priceRentMarks.length == 0) {
      const marks: Mark[] = [];
      rentMarks.forEach((dto, index) => {
        dto.index = index;
        marks.push(dto);
        this.priceRentMarks.push(dto);
      });
      this.priceRentDefault = new PriceModel(lodash.first(marks), lodash.last(marks));
    }

    return Promise.resolve();
  }

  private loadBedroomValues = (): Promise<void> => {

    if (this.bedRoomMarks.length == 0) {
      spaceMarks.forEach((dto, index) => {
        dto.index = index;
        this.bedRoomMarks.push(dto);
      })
    }

    return Promise.resolve();
  }

  private loadBathroomValues = (): Promise<void> => {
    if (this.bathRoomMarks.length == 0) {
      spaceMarks.forEach((dto, index) => {
        dto.index = index;
        this.bathRoomMarks.push(dto);
      })
    }
    return Promise.resolve();
  }

  private loadParkingValues = (): Promise<void> => {
    if (this.parkingMarks.length == 0) {
      spaceMarks.forEach((dto, index) => {
        dto.index = index;
        this.parkingMarks.push(dto);
      })
    }
    return Promise.resolve();
  }

  private loadPropertyTypes = (): Promise<void> => {
    parentTypes.forEach((dto, index) => {
      dto.index = index;
      this.propertyTypeMarks.push(dto);
    })

    return Promise.resolve()
  }

  get mode() {
    return this.searchValues.mode;
  }

  setMode = (mode: string, trigger?: boolean) => {
    if (mode === ModeEnums.buy) {
      // this.marksList.price = buyMarks
      this.searchValues.price = buyDefaults;
      this.defaultsList.price = buyDefaults;
      this.searchValues.mode = ModeEnums.buy
    }
    if (mode === ModeEnums.rent) {

      this.marksList.price = rentMarks;
      this.searchValues.price = rentDefaults;
      this.defaultsList.price = rentDefaults;
      this.searchValues.mode = ModeEnums.rent
    }

    if (mode === ModeEnums.sold) {

      this.marksList.price = buyMarks
      this.searchValues.price = buyDefaults;
      this.defaultsList.price = buyDefaults;
      this.searchValues.mode = ModeEnums.sold
    }

    if (!this.loaded) return;

    if (trigger) {
      if (mode !== ModeEnums.buy) {
        const encodedParams = this.convertedParams.toString().replace(/%2C/g, ',');
        
        if (this.rootStore.history) this.rootStore.history.push('/?' + encodedParams);
      } else {
        if (this.rootStore.history) this.rootStore.history.push('/');
      }

    }
  }

  applyFilters = (values: FilterValues): Promise<void> => {
    this.filtersReady = false;

    if (values.mode !== this.searchValues.mode) {
      this.setMode(values.mode);
      const { mode, price, ...valuesFiltered } = values;
      this.searchValues = { ...this.searchValues, ...valuesFiltered, page: 1 };
      this.triggerSearch();
      return Promise.resolve()
    }

    this.searchValues = { ...this.searchValues, ...values, page: 1 };
    this.triggerSearch()
    return Promise.resolve();
  }

  processParamsFromSearchPage = async (params: LocationParams) => {

    const modePromise = new Promise<void>((resolve, reject) => {
      if (params.mode != null) {
        if (params.mode === "sale") {
          this.setMode(ModeEnums.buy);
        }
        if (params.mode === "rent") {
          this.setMode(ModeEnums.rent);
        }
        resolve();
      }
      resolve();
    })

    const pagePromise = new Promise<void>((resolve, reject) => {
      if (params.pageNumber !== null) {
        this.searchValues.page = parseInt(params.pageNumber);
        resolve();
      }
      resolve();
    })

    const bedRoomsPromise = new Promise<void>((resolve, reject) => {
      if (params.bedRooms !== null) {

        const { value, exactValue } = processSpaceParams(params.bedRooms);
        if (value) {
          if (value !== this.searchValues.bedRooms) {
            this.searchValues.bedRoomsExact = exactValue;
            this.searchValues.bedRooms = value;
          }
        }
        resolve();
      }
      resolve();
    })

    const bathRoomsPromise = new Promise<void>((resolve, reject) => {
      if (params.bathRooms !== null) {
        const { value, exactValue } = processSpaceParams(params.bathRooms);
        if (value) {
          if (value !== this.searchValues.bathRooms) {
            this.searchValues.bathRoomsExact = exactValue;
            this.searchValues.bathRooms = value;
          }
        }
        resolve();
      }
      resolve();
    })

    const parkingPromise = new Promise<void>((resolve, reject) => {
      if (params.parking !== null) {
        const { value, exactValue } = processSpaceParams(params.parking);
        if (value) {
          if (value !== this.searchValues.parking) {
            this.searchValues.parkingExact = exactValue;
            this.searchValues.parking = value;
          }
        }
        resolve();
      }
      resolve();
    })

    const pricePromise = new Promise<void>((resolve, reject) => {
      if (params.price !== null) {
        const prices = processPriceParams(params.price, this.marksList.price);
        if (prices) {

          if (prices[0] !== this.searchValues.price.lowValue) {
            this.searchValues.price.lowValue = prices[0]
          }
          if (prices[1] !== this.searchValues.price.highValue) {
            this.searchValues.price.highValue = prices[1]
          }
        }
        resolve();
      }
      resolve();
    })

    const pTypesPromise = new Promise<void>((resolve, reject) => {
      if (params.pTypes !== null) {
        this.updatePropertyTypes(params.pTypes).then(() => resolve());
      } else {
        resolve();
      }

    })

    const suburbsPromise = new Promise<void>((resolve, reject) => {
      if (params.suburbs !== null) {
        const suburbs = processSuburbsParams(params.suburbs);
        this.findPostcodes(suburbs).then(() => {
          resolve();
        });
      } else {
        resolve()
      }
    })

    await modePromise;
    await pagePromise;
    await bedRoomsPromise;
    await bathRoomsPromise;
    await parkingPromise;
    await pricePromise;
    await pTypesPromise;
    await suburbsPromise;
  }

  get getIsPriceDefault() {
    if (this.searchValues.price.lowValue != initialSearchValues.price.lowValue || this.searchValues.price.highValue != initialSearchValues.price.highValue) return false;
    return true;
  }


  setSlideValue = (value: HiLowValue, target: string) => {
    this.searchValues[target] = value;
  }

  triggerSearch = () => {
    runInAction(() => {
      this.listingsLoaded = false;
    })
    const encodedParams = this.convertedParams.toString().replace(/%2C/g, ',');
    this.rootStore.history.push('/search?' + encodedParams);
    this.paramsChanged = false;
    this.filtersReady = true;
    this.searchListings(encodedParams);
  }

  triggerResetFilters = () => {
    runInAction(() => {
      // this.searchParams = initialSearchParams;
      this.resetFilters = !this.resetFilters;
    })
    this.triggerSearch();
  }

  setPageNumber = (pageNumber: number) => {
    this.listingsLoaded = false;
    this.searchValues.page = pageNumber;
    this.triggerSearch();
  };

  get urlParams() {
    return '';
  }

  get convertedParams() {
    let params = new URLSearchParams();

    if (this.searchValues.page > 1) {
      params.append('pageNumber', this.searchValues.page.toString());
    }

    params.append('pageSize', this.searchValues.pageSize.toString());
    params.append('mode', this.searchValues.mode);

    spacesProcessor(SearchValueEnums.bedRooms, this.searchValues, params, spaceDefaults, this.searchValues.bedRoomsExact);
    spacesProcessor(SearchValueEnums.bathRooms, this.searchValues, params, spaceDefaults, this.searchValues.bathRoomsExact);
    spacesProcessor(SearchValueEnums.parking, this.searchValues, params, spaceDefaults, this.searchValues.parkingExact);
    priceProcessor(SearchValueEnums.price, this.searchValues, params, this.defaultsList.price);
    // stringArrayProcessor(SearchValueEnums.features, this.searchValues, params);
    checkboxProcessor(SearchValueEnums.pTypes, this.searchValues, params);
    suburbsProcessor(this.selectedPostcodes, 'suburbs', params)

    if (this.searchValues.page !== 1) {
      params.append('page', stringifyValue(this.searchValues.page));
    }

    return params;
  }

  setPagination = (pagination: Metadata) => {
    this.pagination = pagination;
  };

  searchListings = async (params: string) => {
    this.status = 'pendingSearch';
    this.listingsLoaded = false;
    try {
      const result = await this.agent.find(this.convertedParams);

      runInAction(() => {
        this.listingRegistry.clear();
        result.items.forEach((listing) => {
          this.setListing(listing);
        });
        this.setPagination(result.pagination);
        this.listingsLoaded = true;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.status = 'idle';
      });
    }
  };

  getListingDetails = async (id: string): Promise<SearchListing> => {
    this.status = 'pendingFind';
    try {
      const result = await this.agent.details(id);

      runInAction(() => {
        this.setListing(result);
      });

      return result;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.status = 'idle';
      });
    }
  }

  get listings() {
    return Array.from(this.listingRegistry.values());
  }

  private setListing = (listing: SearchListing) => {
    this.listingRegistry.set(listing.id, listing);
  };

  getFilters = async () => {
    this.status = 'pendingFilters';
    this.filtersLoaded = false;
    try {
      const lookups = await this.agent.filters();

      runInAction(() => {
        this.features = lookups.features;
        this.propertyTypes = lookups.propertyTypes;
        this.filtersLoaded = true;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.status = 'idle';
      });
    }
  };

  searchPostcodes = async (query: string) => {
    this.status = 'pendingPostcodes';
    this.postcodesLoaded = false;
    try {
      const result = await this.agent.searchPostcode(query);
      runInAction(() => {
        this.availablePostcodesMap.clear();
        result.forEach(postcode => {
          this.availablePostcodesMap.set(postcode.id, postcode)
        });
        this.processAvailablePostcodes()
        this.postcodesLoaded = true;
      });
      return result;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.status = 'idle';
      });
    }
  }

  findPostcodes = async (query: string[]) => {
    this.status = 'pendingPostcodes';
    this.postcodesRegistry.clear();
    try {
      const result = await this.agent.findPostCodes(query);
      runInAction(() => {
        result.forEach(code => {
          this.postcodesRegistry.set(code.id, code);
        })
      });

      return;

    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.status = 'idle';
      });
    }
  }

  setSuburbFilter = (suburbs: Postcode[]) => {
    this.postcodesRegistry.clear();
    suburbs.forEach(suburb => {
      this.postcodesRegistry.set(suburb.id, suburb);
    })
  };

  get selectedPostcodes() {
    return Array.from(this.postcodesRegistry.values());
  }

  get availablePostcodes() {
    return Array.from(this.availablePostcodesMap.values());
  }

  addPostcode = (option: Postcode) => {
    this.postcodesRegistry.set(option.id, option);
    this.availablePostcodesMap.delete(option.id)
  }

  removePostcode = (option: Postcode) => {
    this.postcodesRegistry.delete(option.id);
    this.availablePostcodesMap.set(option.id, option)
  }

  private processAvailablePostcodes = () => {
    const availablePostcodes = Array.from(this.availablePostcodesMap.values());
    let difference = availablePostcodes.filter(x => !this.selectedPostcodes.includes(x));
    difference.forEach(x => {
      this.availablePostcodesMap.set(x.id, x);
    })
  }


  setSelectedListing = (listing: SearchListing) => {
    this.selectedListing = listing;
    this.rootStore.history.push(`/search/${listing.id}`);
  }

  getExtraImages = async (id: string) => {
    const images = await this.agent.extraImages(id);
    return images;
  }

  setToggleSearchPage = () => {
    this.searchPageToggleState = !this.searchPageToggleState
  }

  get toggleSearchPage() {
    return this.searchPageToggleState;
  }

  public getDefaultStatus = (target: string): boolean => {

    if (target === SearchValueEnums.mode) {
      return true;
    }

    if (target === SearchValueEnums.price) {
      if (this.searchValues.price.lowValue !== this.defaultsList.price.lowValue ||
        this.searchValues.price.highValue !== this.defaultsList.price.highValue) {
        return true;
      }
      return false;
    }

    if (target === SearchValueEnums.bedRooms) {
      if (this.searchValues.bedRoomsExact === true) {
        return true;
      }
      if (this.searchValues.bedRooms !== this.defaultsList.bedRooms) {
        return true;
      }
      return false;
    }

    if (target === SearchValueEnums.bathRooms) {
      if (this.searchValues.bathRoomsExact === true) {
        return true;
      }
      if (this.searchValues.bathRooms !== this.defaultsList.bathRooms) {
        return true;
      }
      return false;
    }

    if (target === SearchValueEnums.pTypes) {
      if (this.searchValues.pTypes.length !== 0) {
        return true;
      }
      return false;
    }

  }

  private updatePropertyTypes = (params: string): Promise<void> => {
    const split = params.split(',');

    if (split.length > 0) {
      split.forEach(x => {
        const found = this.propertyTypeMarks.find(item => item.value == x);
        if (found) {
          this.searchValues.pTypes.push(found)
        }
      })
      return Promise.resolve()
    }

    return Promise.resolve();

  }

  get getPropertyTypes() {
    return Array.from(this.searchValues.pTypes.values());
  }

  public fetchLabel = (target: string): string => {

    if (target === SearchValueEnums.pTypes) {
      
      const propertyTypes: FeatureType[] = []

      if (this.searchValues.pTypes.length > 0) {
        
        if (this.searchValues.pTypes.length === 1) {
          return this.searchValues.pTypes[0].label;
        } else {
          return `${this.searchValues.pTypes.length} Property types`
        }

      } else {
        return "Property types"
      }
      
    }

    if (target === SearchValueEnums.mode) return ModeEnumsLabels[this.searchValues.mode];

    if (target === SearchValueEnums.bedRooms) {
      const marks = this.bedRoomMarks;

      const found = marks.find(x => x.value === this.searchValues.bedRooms);

      if (found.label !== 'Any') {
        if (this.searchValues.bedRoomsExact) {
          return `${found.value} beds`
        }
        return `${found.label} beds`
      } else {
        return 'Beds'
      }
    }

    if (target === SearchValueEnums.price) {

      let marks: Mark[];
      let priceDefaults: PriceModel;
      if (this.searchValues.mode === ModeEnums.buy) {
        marks = this.priceBuyMarks;
        priceDefaults = this.priceBuyDefault;
      }
      if (this.searchValues.mode === ModeEnums.rent) {
        marks = this.priceRentMarks;
        priceDefaults = this.priceRentDefault;
      }

      if (this.searchValues.price.lowValue !== priceDefaults.lowMark.value ||
        this.searchValues.price.highValue !== priceDefaults.highMark.value) {
        this.priceDefaultStatus = true;
      } else {
        this.priceDefaultStatus = false;
      }

      return hiLowLabelProcessor(
        {
          values: this.searchValues.price,
          defaults: { lowValue: priceDefaults.lowMark.value, highValue: priceDefaults.highMark.value } as HiLowValue,
          defaultLabel: 'Price',
          marks: marks,
          fromIndex: false
        })
    }

    return "";
  }

}
