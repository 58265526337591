import { AxiosInstance } from "axios";
import { Account } from "./Account.model";
import { requests } from "stores/storeUtils";

export class AccountAgent implements IAccountAgent {
    constructor(private api: AxiosInstance) {
    }

    requests = requests(this.api);

    login(values: any): Promise<Account> {
        return this.requests.post<Account>('account/login', values)
    }

    register(values: any): Promise<Account> {
        return this.requests.post<Account>('account/register', values)
    }

    currentAccount(): Promise<Account> {
        return this.requests.get<Account>('account/me')
    }
}

export interface IAccountAgent {
    login(values: any): Promise<Account>;
    register(values: any): Promise<Account>;
    currentAccount(): Promise<Account>;
}
