import { MainNavBar, OtherLinksBar } from 'layout';
import { useEffect } from 'react'
import { SuburbSearchBox } from './components';
import FilterButtons from './components/FilterButtons';
import { useLocation } from 'react-router-dom'
import { SearchParamsEnums } from 'models/pagination';
import { observer } from 'mobx-react-lite';
import { LocationParams } from './models';
import { Listings } from './components/Listing';
import AppPagination from 'components/AppPagination';
import { useStore } from 'stores';


const ResultsPage = () => {

  const {
    searchStore: {
      loaded,
      listings,
      pagination,
      setPageNumber,
      loadInitialValues
    },
  } = useStore();

  const location = useLocation();

  const initialize = async () => {

    if (!loaded) {
      const params = {
        pageNumber: new URLSearchParams(location.search).get(SearchParamsEnums.pageNumber),
        pageSize: new URLSearchParams(location.search).get(SearchParamsEnums.pageSize),
        mode: new URLSearchParams(location.search).get(SearchParamsEnums.mode),
        bedRooms: new URLSearchParams(location.search).get(SearchParamsEnums.bedRooms),
        bathRooms: new URLSearchParams(location.search).get(SearchParamsEnums.bathRooms),
        parking: new URLSearchParams(location.search).get(SearchParamsEnums.parking),
        price: new URLSearchParams(location.search).get(SearchParamsEnums.price),
        pTypes: new URLSearchParams(location.search).get(SearchParamsEnums.pTypes),
        features: new URLSearchParams(location.search).get(SearchParamsEnums.features),
        suburbs: new URLSearchParams(location.search).get(SearchParamsEnums.suburbs)
      } as LocationParams;
      await loadInitialValues(params, true);
    }
    
  }

  useEffect(() => {
    initialize();
  }, [])

  if (!loaded) return <div>loading...</div>

  return (
    <div className='flex flex-col'>

      <div className='hidden lg:visible lg:block'>
        <div className='flex items-center mx-auto justify-center min-h-[110px] p-3'>Advertising here</div>
      </div>

      <div className='hidden lg:visible lg:block'>
        <OtherLinksBar />
      </div>
      <MainNavBar lightMode={false} />

      <div className='border-dom-grey-light border-t border-b'>
        <div className='max-w-[1280px] flex flex-col lg:flex-row lg:items-center mx-auto justify-start p-4'>
          <div className='w-full'>
            <SuburbSearchBox showTabs={false} showFilterButton={false} smallVersion={true} />
          </div>
          <FilterButtons />
        </div>
      </div>

      <div className='py-6'>
        <div className='max-w-[1280px] flex flex-row mx-auto'>
          <div className='flex flex-col'>
            {pagination && (<div className='text-dom-grey-dark font-bold text-lg'>{pagination.totalItems} Properties advertised</div>)}
            {<Listings listings={listings} />}
            <div>
              {pagination && (
                <div className='mb-4'>
                  <AppPagination
                    metaData={pagination}
                    onPageChange={(page: number) => setPageNumber(page)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default observer(ResultsPage)