import { useCallback, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import AboutPage from "./pages/about/AboutPage";
import ContactPage from "./pages/contact/ContactPage";
import { DetailsPage } from "./area/search/Details";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerError from "common/errors/ServerError";
import NotFound from "common/errors/NotFound";
import LoadingComponent from "./layout/LoadingComponent";

import LoginPage from "./area/account/LoginPage";
import RegisterPage from "./area/account/RegisterPage";
import AdapterLuxon from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { config } from "./config";
import UserProfileWrapper from "./area/user/UserProfileWrapper";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import "./index.css";
import { HomePage } from "pages";
import { SearchResults } from "area/search";
import PrivateRoute from "layout/PrivateRoute";
import ProtectedPage from "area/protected/ProtectedPage";
import { ManagePage } from "area/manage";
import { useStore } from "stores";



let key: string = "";

key = process.env.REACT_APP_STRIPE_KEY!;

const stripePromise = loadStripe(
  key
);

function App() {
  const { accountStore, userStore } = useStore();
  const [loading, setLoading] = useState(true);

  const initApp = useCallback(async () => {
    try {
      const account = await accountStore.getcurrentAccount();
      if (account) {
        await userStore.getCurrentUser();
      }
    } catch (error) {
    }
  }, [accountStore, userStore]);

  useEffect(() => {
    initApp().then(() => setLoading(false));
  }, [initApp]);

  if (loading) return <LoadingComponent message="Initialising app..." />;

  return (
    <div>
      <Elements stripe={stripePromise}>
        <LocalizationProvider locale={config.locale} dateAdapter={AdapterLuxon}>
          <ToastContainer
            position="bottom-right"
            hideProgressBar
            theme="colored"
          />

          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/about" component={AboutPage} />
            <Route exact path="/contact" component={ContactPage} />
            <Route exact path="/search" component={SearchResults} />
            <Route exact path="/search/:id" component={DetailsPage} />
            <Route exact path="/server-error" component={ServerError} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/register" component={RegisterPage} />
            <Route
              exact
              path="/user/complete-profile"
              component={UserProfileWrapper}
            />
            <PrivateRoute exact path="/protected" component={ProtectedPage} />
            <PrivateRoute path="/manage" component={ManagePage} />
            <Route component={NotFound} />
          </Switch>

        </LocalizationProvider>
      </Elements>
    </div>
  );
}

export default App;
