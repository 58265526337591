import { Settings as LuxonSettings } from 'luxon';

const development = /^https?:\/\/localhost/.test(window.location.toString());


export interface RuntimeConfig {

    /** The locale as picked up from the browser, or en-AU as the default */
    locale: string;

    /** The format pattern used for dates, e.g. 'dd/mm/yyy' */
    dateFormatPattern: string;

    /** Flag to check if the app is being served from a localhost URL */
    development: boolean;
}

const envConfig = {
    locale: 'en-AU',
    dateFormatPattern: 'dd/mm/yyyy',
    development
};

export const config = envConfig as RuntimeConfig;

// console.log(`Setting locale for date/times to: ${config.locale}`);
LuxonSettings.defaultLocale = config.locale;