import { Button } from "components/ui/Button"


const ManageHomeCn = () => {
    return (
        <div className='flex flex-col gap-3'>
            <div>
            <Button variant="ghost" size="lg">This is a ShadCn button</Button>
            </div>
            {/* <Paper variant='elevation' sx={{ p: 3 }}>
                <Stack direction="column" spacing={1}>
                    <Typography variant='h6' align='center'>Welcome!</Typography>
                    <Typography variant='body2'>Realestateify is a <Link target="_blank" href="https://domain.com.au">
                        Domain.com.au
                    </Link> <i>clone</i>, and is a 'DEMO ONLY' App. Its designed to show off <Link target="_blank" href="https://nigeldewar.vs4.one">Nigel's</Link> skills.</Typography>
                    <Typography variant='body2'>Realestateifys public front-end replicates Domain's public site in functionality and look. <Link target="_blank" href="https://tailwindcss.com/">
                        Tailwind
                    </Link> is the styling system used for this.</Typography>
                    <Typography variant='body2'>Here you are in the Admin back-end which is all custom built and does not replicate any of Domains back-end. It us built using <Link target="_blank" href="https://mui.com/">
                    Material UI
                    </Link> and is of my own design.</Typography>
                    <Typography variant='body2'>Realestateify is built using React, TypeScript, DotNet Core 6, and features micro-services architecure hosted on Docker Swarm.</Typography>
                    <Typography variant='body2'>I will be turning Realestateify into a comprehensive YouTube course, which will include mobile 'React Native' Apps as well.</Typography>

                </Stack>
            </Paper>

            <Paper variant='elevation' sx={{ p: 3 }}>
                <Stack direction="column" spacing={1}>

                    <Typography variant='h6' align='center'>Create an advert</Typography>
                    <Typography variant='body2'>This App is Live, but it is not indexed by any search engines. You are free to list properties for sale or rent. Please just be mindful its best to use fake mobile phone numbers.</Typography>
                    <Stack spacing={2} direction="row" justifyContent={'center'}>
                        <Button href="/manage/create-ad" size="large" variant='contained'>Create</Button>
                    </Stack>
                </Stack>
            </Paper> */}

        </div>
    )
}

export default ManageHomeCn