import * as yup from 'yup';

const IS_REQUIRED = 'is required.'

export const userValidationSchema = [
    yup.object({
        firstName: yup.string().required('First Name ' + IS_REQUIRED),
        lastName: yup.string().required('Last Name ' + IS_REQUIRED),
        emailAddress: yup.string().required('Email ' + IS_REQUIRED),
        mobileNumber: yup.string().required('Mobile ' + IS_REQUIRED)
    }),
] 