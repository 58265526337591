import { Typography } from "@mui/material";

export default function ContactPage() {
    return (
        <>
            <Typography variant='h2'>
                Contact Page
            </Typography>
        </>
    )
}